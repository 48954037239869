// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const navConfig = [
  {
    title: "explore",
    path: "/explore",
    icon: getIcon("fa-brands:slack-hash"),
  },
  {
    title: "messages",
    path: "/messages",
    icon: getIcon("bi:chat-left-heart-fill"),
  },
  {
    title: "likes",
    path: "/likes",
    icon: getIcon("ant-design:heart-filled"),
  },
  {
    title: "preferences",
    path: "/preferences",
    icon: getIcon("akar-icons:settings-horizontal"),
  },
  // {
  //   title: "Answer to like me",
  //   path: "/myquestions",
  //   icon: getIcon("akar-icons:question-fill"),
  // },
  // {
  //   title: "Automatch",
  //   path: "/automatch",
  //   icon: getIcon("bi:heart-arrow"),
  // },
  // {
  //   title: "Events",
  //   path: "/events",
  //   icon: getIcon("bi:calendar-heart-fill"),
  // },
  // {
  //   title: "Match Room",
  //   path: "/events",
  //   icon: (
  //     <img src="/static/illustrations/illustration_login.png" alt="login" />
  //   ),
  // },
];

export const mobileNavConfig = [
  {
    title: "preferences",
    path: "/preferences",
    icon: getIcon("akar-icons:settings-horizontal"),
  },
];
