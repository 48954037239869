import { Card, Container, Typography, Box } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import Iconify from "../components/Iconify";
// components
import Page from "../components/Page";
// hooks
import useResponsive from "../hooks/useResponsive";
// sections
import { LoginForm } from "../sections/auth/login";
import { useTranslation } from "react-i18next";
import LanguageSelector from "src/sections/@language/LanguageSelector";
import "./login.css";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive("up", "md");
  const [t] = useTranslation("global");
  const intensions = t("login.intentions", { returnObjects: true });
  const features = t(["login.features"], {
    returnObjects: true,
    defaultValue: [],
  });
  const tagline = t("login.tagline", { returnObjects: true });
  return (
    <Page>
      <RootStyle>
        <HeaderStyle>
          <Box>
            <Iconify
              icon={"ant-design:heart-filled"}
              color={"#ee224b"}
              height={80}
              width={80}
              style={{ margin: 0, transform: [{ rotate: "30deg" }] }}
            />
            <Iconify
              icon={"ant-design:heart-filled"}
              color={"#ee224b"}
              height={80}
              width={80}
              style={{ marginLeft: -40, transform: [{ rotate: "30deg" }] }}
            />
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" textAlign={"center"}>
              {t("login.welcome")}
            </Typography>
            <br />
            <Typography variant="h5" padding={5}>
              <p className="sentence1">{tagline[0]}</p>
              <p className="sentence2">{tagline[1]}</p>
              <p className="sentence3">{tagline[2]}</p>
            </Typography>

            <Typography textAlign={"center"}>
              {t("login.tagline2")}

              <span className="heart-container">
                <span role="img" aria-label="beating heart">
                  &#x1F493;
                </span>
              </span>
            </Typography>
            <img
              src="/static/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                display: "flex",
                lineHeight: "40px",
                flexWrap: "wrap",
                fontSize: "1em",
              }}
            >
              <LanguageSelector />
              {t("login.aboutMessage")}
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              {intensions[0]}
              <br />
              {intensions[1]}
            </Typography>
            <ul>
              <Typography sx={{ color: "text.secondary", mb: 5 }}>
                {t("login.featureMessage")}

                {features?.map((v, i) => (
                  <li key={`feature-${i}`}>{v}</li>
                ))}
              </Typography>
            </ul>

            {/* <AuthSocial /> */}

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
