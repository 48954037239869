import { createContext, useContext, useState, useEffect } from "react";
import { PreferencesService } from "src/services/PreferencesService";

export const defaultPreferencesState = {
  education: [],
  country: [],
  homeCountry: [],
  maritalStatus: [],
  profession: [],
  practicing: [],
  pray: [],
  canRelocate: false,
  wearsHijab: false,
  minAge: 18,
  maxAge: 100,
  minHeight: 160,
  maxHeight: 190,
};

export const PreferencesContext = createContext(defaultPreferencesState);

export const usePreferences = () => {
  return useContext(PreferencesContext);
};

export function PreferencesProvider({ children }) {
  const [preferences, setPreferences] = useState();

  const getUserPreferences = async () => {
    const response = await PreferencesService.getUserPreferences();
    setPreferences(response.data);
  };

  useEffect(() => {
    getUserPreferences();
  }, []);

  const updatePreferences = (next) => {
    setPreferences(next);
  };

  return (
    <PreferencesContext.Provider value={{ preferences, updatePreferences }}>
      {children}
    </PreferencesContext.Provider>
  );
}
