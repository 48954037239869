import {
  Alert,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import { useTranslation } from "react-i18next";
import DropdownFilter from "./DropdownFilter";

const HeightFilter = ({ toggleView }) => {
  const [preferences, setpreferences] = useState();
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);
  const [heightUnit, setHeightUnit] = useState("cm");
  const [t] = useTranslation("global");

  const saveHeightFilter = async () => {
    try {
      setprocessing(true);
      const heightRangeFilter = {
        section: "heightRange",
        heightRange: preferences.heightRange,
      };
      await PreferencesService.updatePreferences(heightRangeFilter);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };
  const onChange = (selection) => {
    const p = { ...preferences, heightRange: selection };
    setpreferences(p);
  };

  const heightRangesObj = t("heightRanges", { returnObjects: true });
  const hieghtRangesList = Object.entries(heightRangesObj).map((h) => {
    return { value: h[0], label: h[1][heightUnit], height: h[1] };
  });
  const getHeightLabels = (keys) => {
    if (keys?.length > 0) {
      return hieghtRangesList
        .filter((e) => keys.includes(e.value))
        .map((e) => e.height[heightUnit]);
    }
    return [];
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"} padding={1}>
      {/* <Stack paddingLeft={2} paddingRight={2}>
        <Slider
          min={100}
          max={215}
          getAriaLabel={() => "Age range"}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Chip
          style={{ fontSize: "x-small" }}
          label={`${minHeight} - ${maxHeight} cm`}
        />
        <Chip
          style={{ fontSize: "x-small" }}
          label={`${convertHeight(minHeight, "feet")} - ${convertHeight(
            maxHeight,
            "feet"
          )} feet`}
        />
        <Chip
          style={{ fontSize: "x-small" }}
          label={`${convertHeight(minHeight, "meter")} - ${convertHeight(
            maxHeight,
            "meter"
          )} mtr`}
        />
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <TextField
          label={"Min"}
          type={"number"}
          value={minHeight}
          name="minAge"
          onChange={onAgeChange}
          style={{ width: 60 }}
          onFocus={(event) => {
            event.target.select();
          }}
        />
        <TextField
          label={"Max"}
          type={"number"}
          value={maxHeight}
          name="maxAge"
          onChange={onAgeChange}
          style={{ width: 60 }}
          onFocus={(event) => {
            event.target.select();
          }}
        />
      </Stack> */}
      <Stack>
        <FormControl>
          <ButtonGroup style={{justifyItems:"right"}}>
            <Button
              variant={heightUnit === "cm" ? "contained" : "outlined"}
              onClick={() => setHeightUnit("cm")}
            >
              {t("profile.aboutMe.cm")}
            </Button>
            <Button
              variant={heightUnit === "feet" ? "contained" : "outlined"}
              onClick={() => setHeightUnit("feet")}
            >
              {t("profile.aboutMe.feet")}
            </Button>
            <Button
              variant={heightUnit === "meter" ? "contained" : "outlined"}
              onClick={() => setHeightUnit("meter")}
            >
              {t("profile.aboutMe.meters")}
            </Button>
          </ButtonGroup>
        </FormControl>
        <DropdownFilter
          data={hieghtRangesList}
          placeholder={t("preferences.height")}
          labelField={"label"}
          valueField={"value"}
          name="heightRange"
          initialValue={preferences?.heightRange || []}
          onChange={onChange}
          renderValue={(value) =>
            getHeightLabels(value).map((m) => (
              <Chip key={`${m}`} label={`${m}`} style={{ margin: 2 }} />
            ))
          }
          xs={12}
        />
      </Stack>

      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveHeightFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};

export default HeightFilter;
