import { ConfigManager } from "./config/ConfigManager";
import { USER_ACTIONS } from "./constants/user-actions";
import { RestHelper } from "./RestHelper";

const userActionsBaseUrl = ConfigManager.getUrl("userActions");
const userLikesBaseUrl = ConfigManager.getUrl("userLikes");
const reportUserBaseUrl = ConfigManager.getUrl("reportUserApi");

const sendLike = async (uids) => {
  if (uids?.length > 0) {
    const payload = uids.map((uid) => {
      return {
        uid: uid,
        action: USER_ACTIONS.LIKES,
      };
    });
    return await sendAction(payload);
  }
};

const sendPass = async (uids) => {
  if (uids?.length > 0) {
    const payload = uids.map((uid) => {
      return {
        uid: uid,
        action: USER_ACTIONS.PASSED,
      };
    });
    return await sendAction(payload);
  }
};

const reportUsser = async (uids) => {
  if (uids?.length > 0) {
    const payload = {
      reportedUid: uids,
    };
    return await RestHelper.post(`${reportUserBaseUrl}reported-users`, payload);
  }
};

const sendUnmatch = async (likedUser) => {
  return await RestHelper.post("useractions/unmatch", likedUser);
};

const getMyLikes = async (user) => {
  try {
    const response = await RestHelper.get(userLikesBaseUrl + "likes/my-likes");
    return response;
  } catch (error) {}
  return [];
};
const getLiked = async (user) => {
  try {
    const response = await RestHelper.get(userLikesBaseUrl + "likes/likes");
    return response;
  } catch (error) {}
  return [];
};
const getPassed = async (user) => {
  try {
    const response = await RestHelper.get(userLikesBaseUrl + "likes/passes");
    return response;
  } catch (error) {}
  return [];
};
const getUnmatched = async () => {
  try {
    const response = await RestHelper.get("useractions/unmatches");
    return response.data;
  } catch (error) {}
  return [];
};
const getMyMatches = async () => {
  try {
    const response = await RestHelper.get(
      userLikesBaseUrl + "likes/my-matches"
    );
    return response;
  } catch (error) {}
  return [];
};

const sendAction = async (action) => {
  return await RestHelper.post(`${userActionsBaseUrl}user-actions`, action);
};

export const LikeService = {
  getMyLikes,
  getMyMatches,
  getLiked,
  getPassed,
  getUnmatched,
  sendLike,
  sendPass,
  sendUnmatch,
  reportUsser,
};
