import { Box } from "@mui/material";
import { VIDEO_TYPES } from "src/constants/collections";
import { useAuthUser } from "src/contexts/UserContext";
import { getFileUrl } from "src/utils/photoutil";

export default function RenderMedia({ file }) {
  const { type } = file;
  const user = useAuthUser();
  const uid = user.idToken.payload.sub;
  const url = getFileUrl(uid, file);
  if (VIDEO_TYPES.includes(type)) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <video
          autoPlay={true}
          muted
          loop
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        >
          <source src={url} type="video/mp4" />
          <source src={url} type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      </Box>
    );
  }
  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <img src={url} alt="image" loading="lazy" />
    </Box>
  );
}
