// LanguageSelector.js
import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const [cookies, setCookie] = useCookies(["selectedLanguage"]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    cookies.selectedLanguage || "en"
  );
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    setCookie("selectedLanguage", selectedLanguage, { path: "/" });
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, setCookie]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const flagStyle = {
    fontSize: "2.5em", // Adjust the font size as needed
    marginRight: "8px", // Optional: Add some spacing between flag and text
  };
  const menuItemStyle = {
    fontSize: "1.5em", // Adjust the font size as needed
    display: "flex", // Align flag and text horizontally
    alignItems: "center", // Center vertically
  };

  return (
    <FormControl fullWidth style={{ paddingTop: "30px" }}>
      <Select
        value={selectedLanguage}
        onChange={handleLanguageChange}
        style={{ minWidth: "120px" }}
      >
        <MenuItem value="en">
          <span role="img" aria-label="English Flag" style={flagStyle}>
            🇺🇸
          </span>{" "}
          <span>English</span>
        </MenuItem>
        <MenuItem value="de">
          <span role="img" aria-label="German Flag" style={flagStyle}>
            🇩🇪
          </span>{" "}
          German
        </MenuItem>
        <MenuItem value="fr">
          <span role="img" aria-label="French Flag" style={flagStyle}>
            🇫🇷
          </span>{" "}
          French
        </MenuItem>
        <MenuItem value="ar">
          <span role="img" aria-label="Arabic Flag" style={flagStyle}>
            🇦🇪
          </span>{" "}
          Arabic
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
