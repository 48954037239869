import { Alert, Button, Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import DropdownFilter from "./DropdownFilter";
import { useTranslation } from "react-i18next";

const EducationFilter = ({ toggleView }) => {
  const [t] = useTranslation("global");
  const educationData = [
    { value: "phd", label: t("profile.basicInfo.qualifications.phd") },
    { value: "masters", label: t("profile.basicInfo.qualifications.masters") },
    {
      value: "graduate",
      label: t("profile.basicInfo.qualifications.bachelors"),
    },
    {
      value: "undergraduate",
      label: t("profile.basicInfo.qualifications.underGraduate"),
    },
    { value: "other", label: t("profile.basicInfo.qualifications.other") },
  ];

  const [preferences, setpreferences] = useState();
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);

  const onChange = (selection) => {
    const p = { ...preferences, education: selection };
    setpreferences(p);
  };
  const saveEducationFilter = async () => {
    try {
      setprocessing(true);
      const educationPreferences = {
        section: "education",
        education: preferences.education,
      };
      await PreferencesService.updatePreferences(educationPreferences);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };
  const getEducationLabels = (keys) => {
    if (keys?.length > 0) {
      return educationData
        .filter((e) => keys.includes(e.value))
        .map((e) => e.label);
    }
    return [];
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"}>
      <DropdownFilter
        data={educationData}
        placeholder={t("preferences.education")}
        labelField={"label"}
        valueField={"value"}
        name="education"
        initialValue={preferences?.education||[]}
        onChange={onChange}
        renderValue={(value) =>
          getEducationLabels(value).map((m) => (
            <Chip key={`${m}`} label={`${m}`} style={{ margin: 2 }} />
          ))
        }
      />

      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveEducationFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};
export default EducationFilter;
