import {
  Card,
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  ListItemButton,
} from "@mui/material";
import Iconify from "src/components/Iconify";

import { MessageSender } from "./MessageSender";
import { MessageList } from "./MessageList";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import { unmatchUser } from "src/graphql/mutations";
import { useNavigate } from "react-router-dom";

let isReportAction = false;

const UserMessages = ({ conversation, backCallback, onUnmatch }) => {
  const containerRef = useRef(null);

  const [openDialog, setopenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [t] = useTranslation("global");
  const [dialogTitle, setdialogTitle] = useState();
  const [dialogText, setdialogText] = useState();
  const [dialogAction, setdialogAction] = useState();

  const [unmatchUserMutation, { loading, error }] = useMutation(
    gql(unmatchUser)
  );

  const deleteConversation = async (conversationId, matchUid) => {
    const { data, errors } = await unmatchUserMutation({
      variables: {
        conversationId: conversationId,
        matchUid: matchUid,
      },
    });
  };

  const confirmDialogAction = async (conversation) => {
    if (isReportAction === true) {
      await LikeService.reportUsser([conversation.matchUid]);
    } else {
      await LikeService.sendPass([conversation.matchUid]);
    }
    await deleteConversation(
      conversation.conversationId,
      conversation.matchUid
    );
    setopenDialog(false);
    if (onUnmatch) {
      onUnmatch(conversation);
    }
  };

  const showDialogToConfirmUnmach = () => {
    setdialogTitle(t("messages.unmatchUser"));
    setdialogText(t("messages.confirmUnmatch"));
    setdialogAction(t("messages.unmatchUser"));
    handleClose();
    setopenDialog(true);
    isReportAction = false;
  };
  const showDialogToConfirmReport = () => {
    setdialogTitle(t("feed.reportUser"));
    setdialogText(t("feed.confirmReportUser"));
    setdialogAction(t("feed.reportUser"));
    handleClose();
    setopenDialog(true);
    isReportAction = true;
  };
  const handleClick = (event) => {
    isReportAction = false;
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    isReportAction = false;
  };

  const navigate = useNavigate();
  const showProfile = async () => {
    navigate("/user-info", {
      replace: true,
      state: { uid: conversation.matchUid },
    });
  };
  return (
    <Card ref={containerRef}>
      <Dialog open={openDialog} onClose={() => setopenDialog(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setopenDialog(false)}>
            {t("general.cancel")}
          </Button>
          <Button onClick={() => confirmDialogAction(conversation)}>
            {dialogAction}
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        paddingRight={5}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <IconButton onClick={() => backCallback()}>
            <Iconify
              icon="eva:arrow-ios-back-fill"
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>
          <ListItemButton onClick={showProfile}>
            <Avatar src={conversation?.userInfo?.photoUrl} />
            <Typography variant="h6" marginLeft={1}>
              {conversation?.userInfo?.name}
            </Typography>
          </ListItemButton>
        </Stack>
        <Box
          style={{
            justifyContent: "right",
            display: "flex",
          }}
        >
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Iconify icon="pepicons-pop:dots-y" width={30} height={30} />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={showDialogToConfirmUnmach}>
              <Iconify
                color="#FF6347"
                icon="ic:round-heart-broken"
                width={20}
                height={20}
              />
              <span style={{ paddingLeft: 10 }}>{t("messages.unmatch")}</span>
            </MenuItem>
            <MenuItem onClick={showDialogToConfirmReport}>
              <Iconify
                color="#FF6347"
                icon="material-symbols:block"
                width={20}
                height={20}
              />
              <span style={{ paddingLeft: 10 }}>{t("feed.reportUser")}</span>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
      <Divider />

      <MessageList conversation={conversation} containerRef={containerRef} />
      <MessageSender conversationId={conversation.conversationId} />
    </Card>
  );
};

export default UserMessages;
