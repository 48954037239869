import { RestHelper } from './RestHelper';

const getEvents = async () => {
    try {
        const response = await RestHelper.get('events');
        return response.data;
    } catch (error) {
        console.log(error);
    }
    return [];
};
const particpate = async (eventId, guests) => {
    const data = { eventId: eventId, guests: guests };
    console.log(data);
    const response = await RestHelper.post('events', data);
    console.log(response);
};
const getReservation = async (eventId) => {
    const data = { eventId: eventId };
    const response = await RestHelper.post('events/reservation', data);
    return response.data;
};

export const EventsService = {
    getEvents,
    particpate,
    getReservation
};