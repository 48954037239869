import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { ConfigManager } from "src/services/config/ConfigManager";
import UserAuthService from "src/services/UserAuthService";

//Note: url must not end with /
const API_URL =ConfigManager.getUrl("messenger");
const region = ConfigManager.getValue("region");

const auth = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    const { authToken } = await UserAuthService.getAuthTokens();
    return `Bearer ${authToken}`;
  },
};

const httpLink = new HttpLink({ uri: API_URL });

const link = ApolloLink.from([
  createAuthLink({ API_URL, region, auth }),
  createSubscriptionHandshakeLink(
    { url:API_URL, region, auth, keepAliveTimeoutMs: 500000 },
    httpLink
  ),
]);

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
