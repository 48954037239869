// scroll bar
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { createRoot } from 'react-dom/client';

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AuthenticationProvider } from "./contexts/UserContext";
import i18next from "i18next";
import global_en from "./translations/en/global.json";
import global_de from "./translations/de/global.json";
import global_fr from "./translations/fr/global.json";
import global_ar from "./translations/ar/global.json";
import { I18nextProvider } from "react-i18next";
// ----------------------------------------------------------------------
i18next.init({
  interpoloation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    de: {
      global: global_de,
    },
    fr: {
      global: global_fr,
    },
    ar: {
      global: global_ar,
    },
  },
});

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <I18nextProvider i18n={i18next}>
    <HelmetProvider>
      <BrowserRouter>
        <AuthenticationProvider>
          <App />
        </AuthenticationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </I18nextProvider>,
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
