// material
import { Button, Box, Card, CardContent, Container, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
// components
import Iconify from '../components/Iconify';
import Page from '../components/Page';

import { ContentSkeleton } from 'src/sections/@main/UserFeed/UserCardSkeleton';
import EventCard from '../sections/@main/events/EventCard';
import { EventsService } from '../services';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Events() {
    const EVENTS = 0, EVENT = 1;
    const [view, setview] = useState(EVENTS);
    const [events, setevents] = useState();
    const [event, setevent] = useState();

    const getEvents = async () => {
        const data = await EventsService.getEvents();
        setevents(data || []);
    };
    const participateInEent = (ev) => {
        console.log('event changed', ev);
        setevent(ev);
        setview(EVENT);
    };
    useEffect(() => {
        getEvents();
    }, []);

    const NoEvents = () => {
        return (
            <Box flex={1} justifyContent={'center'}>
                <Typography textAlign={'center'} variant='h4' color={'GrayText'}> There are no events in your city yet.</Typography>
            </Box>
        );
    };
    const EventParticiate = () => {
        const [guests, setguests] = useState([]);
        const [guestType, setguestType] = useState('');
        const [reservation, setreservation] = useState();
        const [loading, setloading] = useState(true);

        const onInputChange = (e) => {
            setguestType(e.target.value);
        };

        const addGuest = () => {
            if (guestType) {
                setguests(prev => [...prev, guestType]);
                setguestType('');
            }
        };

        const removeGuest = (g) => {
            const newGuests = [];
            for (let i = 0; i < guests.length; i++) {
                if (i !== g) {
                    newGuests.push(guests[i]);
                }
            }
            setguests(newGuests);
        };

        const reserveSeat = async () => {
            await EventsService.particpate(event.id, guests);
            setview(EVENTS);
        };
        const getReservation = async () => {
            const response = await EventsService.getReservation(event.id);
            if (response.reserved === true) {
                setreservation(response.reservation);
            }
            setloading(false);
        };
        useEffect(() => {
            getReservation();
        }, []);

        return (
            <Stack flex={1} direction='row' justifyContent='center'>
                <Grid xs={12} md={6}>
                    <Stack flex={1}>
                        <Card>
                            <CardContent>
                                {reservation && <Stack spacing={2} justifyContent='center' alignItems={'center'}>
                                    <Typography color={'green'} variant='h5'>You reservation is confirmed</Typography>
                                    {reservation && reservation.guests.length > 0 && <Box>
                                        {reservation.guests.map((g,i) => <Typography variant='body1'>{i+1}. {g}</Typography>)}
                                    </Box>
                                    }
                                    <Button color='error' onClick={() => setview(EVENTS)} >Back</Button>
                                </Stack>
                                }
                                {!reservation && <Stack spacing={3}>
                                    {guests.map((g, i) => {
                                        return (
                                            <Stack active direction={'row'} justifyContent='space-between' alignItems={'center'}>
                                                <Typography variant='body1'>{g}</Typography>
                                                <IconButton onClick={() => removeGuest(i)}>
                                                    <Iconify icon={'ant-design:delete-filled'} height={22} width={22} />
                                                </IconButton>
                                            </Stack>);
                                    })}
                                    {guests && guests.length < 3 && <Stack spacing={1} direction={'row'} >
                                        <FormControl fullWidth size='small'>
                                            <InputLabel id="-select-label">Coming with someone?</InputLabel>
                                            <Select
                                                labelId="comingWith-select-label"
                                                id="comingWith-select"
                                                label="Education"
                                                value={guestType}
                                                onChange={onInputChange}
                                                name="education"
                                            >
                                                <MenuItem value={'Family memeber'}>Family memeber</MenuItem>
                                                <MenuItem value={'Friend'}>Friend</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button onClick={addGuest}>Add</Button>
                                    </Stack>}
                                    <Button variant='outlined' onClick={reserveSeat}>Reserve</Button>
                                    <Button color='error' onClick={() => setview(EVENTS)} >Cancel</Button>
                                </Stack>}
                            </CardContent>
                            {loading && <LinearProgress />}
                        </Card>
                    </Stack>
                </Grid>
            </Stack>
        );
    };
    return (
        <Page title="Events">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" gutterBottom>
            Events
                    </Typography>
                </Stack>
                <Grid container spacing={3} >
                    {!events && <Grid item flex={1} justifyContent='center'><ContentSkeleton /></Grid>}
                    {view === EVENTS && events && events.length === 0 && <NoEvents />}

                    {view === EVENTS && events && events.map((event, index) => (
                        <EventCard key={`${event.id}`} event={event} index={index} participateCallback={() => participateInEent(event)} />
                    ))}
                    {view === EVENT && <EventParticiate />}
                </Grid>
            </Container>
        </Page>
    );
}
