import { CognitoUserPool } from "amazon-cognito-identity-js";
import { ConfigManager } from "./config/ConfigManager";

const cognitoConfig = ConfigManager.getValue("cognito");
const userPool = new CognitoUserPool(cognitoConfig);

export const getCurrentUser = () =>
  new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          resolve(session);
        }
      });
    } else {
      resolve(undefined);
    }
  });

const getUserAttributes = () =>
  new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          resolve(null);
        } else {
          currentUser.getUserAttributes((err, attributes) => {
            if (err) {
              reject(err);
            } else {
              const result = {};
              attributes.forEach((a) => {
                let key = a.Name;
                const customIndexKey = "custom:";
                const customIndex = a.Name.indexOf(customIndexKey);
                if (customIndex >= 0) {
                  key = a.Name.substring(customIndexKey.length);
                }
                result[key] = a.Value;
              });
              resolve(result);
            }
          });
        }
      });
    } else {
      reject(null);
    }
  });

const getUid = async () => {
  const user = await getCurrentUser();
  return user.idToken.payload.sub;
};
const getAuthTokens = async () => {
  const user = await getCurrentUser();
  return {
    accessToken: user?.accessToken?.jwtToken,
    authToken: user?.idToken?.jwtToken,
  };
};
const signout = async () => {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    currentUser.signOut();
  }
};
const UserAuthService = {
  userPool,
  getCurrentUser,
  getUserAttributes,
  getUid,
  getAuthTokens,
  signout,
};
export default UserAuthService;
