import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import MainSidebar from "./MainSidebar";
import ProfileUpdator from "src/pages/ProfileUpdate";
import MobileNavbar, {
  MOBILE_NAVBAR_HEIGHT,
} from "src/components/MobileNavbar";
// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  // minHeight: "100%",
  flex:1,
  height: "100vh",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  // paddingTop: 25,
  paddingBottom: 0,
  // [theme.breakpoints.up("lg")]: {
  //   paddingTop: 25,
  // },
  height: "100vh",
  // marginBottom: MOBILE_NAVBAR_HEIGHT,
  // padding:10
}));

// ----------------------------------------------------------------------

export default function MainLayout() {
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      {/* <MainNavbar onOpenSidebar={() => setOpen(true)} /> */}
      <MainSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <MobileNavbar
        sx={{ display: { xs: "block", lg: "none" } }}
        onOpenSidebar={() => setOpen(true)}
      />
    </RootStyle>
  );
}

export function InitialMainLayout() {
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      {/* <MainNavbar onOpenSidebar={() => setOpen(true)} /> */}
      <MainSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <ProfileUpdator />
      </MainStyle>
      <MobileNavbar
        sx={{ display: { xs: "block", sm: "none" } }}
        onOpenSidebar={() => setOpen(true)}
      />
    </RootStyle>
  );
}
