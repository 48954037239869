import { Alert, Button, Chip, Stack, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import DropdownFilter from "./DropdownFilter";
import { useTranslation } from "react-i18next";

const ReligiosityFilter = ({ toggleView }) => {
  const [t] = useTranslation("global");
  const [preferences, setpreferences] = useState();
  const [wearsHijab, setWearsHijab] = useState(false);
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);

  const onChangePracticing = (selection) => {
    const p = { ...preferences, practicing: selection };
    setpreferences(p);
  };
  const onChangePray = (selection) => {
    const p = { ...preferences, pray: selection };
    setpreferences(p);
  };
  const saveReligiosityFilter = async () => {
    try {
      setprocessing(true);
      const religiosityFilter = {
        section: "religiosity",
        practicing: preferences.practicing,
        pray: preferences.pray,
        wearsHijab: wearsHijab,
      };
      await PreferencesService.updatePreferences(religiosityFilter);

      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
      setWearsHijab(!!p.wearsHijab);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };

  const religiosityData = [
    {
      status: "practicing",
      label: t("profile.religiosity.practicing"),
    },
    {
      status: "notPracticing",
      label: t("profile.religiosity.notPracticing"),
    },
  ];
  const prayData = [
    {
      status: "pray",
      label: t("profile.religiosity.pray"),
    },
    {
      status: "notPray",
      label: t("profile.religiosity.notPray"),
    },
  ];

  const getReligiosityLabels = (keys) => {
    if (keys?.length > 0) {
      return religiosityData
        .filter((r) => keys.includes(r.status))
        .map((r) => r.label);
    }
    return [];
  };
  const getPrayLabels = (keys) => {
    if (keys?.length > 0) {
      return prayData
        .filter((p) => keys.includes(p.status))
        .map((p) => p.label);
    }
    return [];
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"}>
      <DropdownFilter
        data={religiosityData}
        placeholder={t("preferences.practicing")}
        labelField={"label"}
        valueField={"status"}
        name="practicing"
        initialValue={preferences?.practicing || []}
        onChange={onChangePracticing}
        renderValue={(value) =>
          getReligiosityLabels(value).map((m) => (
            <Chip key={`${m}`} label={m} style={{ margin: 2 }} />
          ))
        }
      />
      <DropdownFilter
        data={prayData}
        placeholder={t("preferences.pray")}
        labelField={"label"}
        valueField={"status"}
        name="pray"
        initialValue={preferences?.pray || []}
        onChange={onChangePray}
        renderValue={(value) =>
          getPrayLabels(value).map((m) => (
            <Chip key={`${m}`} label={m} style={{ margin: 2 }} />
          ))
        }
      />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography>{t("preferences.wearsHijab")}</Typography>
        <Switch checked={wearsHijab} onChange={(e, v) => setWearsHijab(v)} />
      </Stack>
      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveReligiosityFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};

export default ReligiosityFilter;
