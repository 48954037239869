/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessageByConversationId = /* GraphQL */ `
  subscription OnCreateMessageByConversationId($conversationId: ID) {
    onCreateMessageByConversationId(conversationId: $conversationId) {
      conversationId
      createdAt
      text
      owner
      __typename
    }
  }
`;
