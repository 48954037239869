/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listConversations = /* GraphQL */ `
  query ListConversations($limit: Int) {
    listConversations(limit: $limit) {
      items {
        uid
        conversationId
        matchUid
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($conversationId: ID!, $sortDirection: ModelSortDirection) {
    listMessages(
      conversationId: $conversationId
      sortDirection: $sortDirection
    ) {
      items {
        conversationId
        createdAt
        text
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
