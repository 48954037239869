import axios from "axios";
import UserAuthService from "./UserAuthService";

const _verifyResponse = async (url, requestOptions) => {
  const { authToken, accessToken } = await UserAuthService.getAuthTokens();

  const headers = {
    ...requestOptions.headers,
    "Content-Type": "application/json",
    Authorization: authToken,
    // AccessToken: accessToken,
  };
  requestOptions.headers = headers;
  const resp = await fetch(url, requestOptions);
  if (!resp.ok) {
    throw resp;
  }
  return resp;
};
const createHeaders = async (headers) => {
  const { authToken} = await UserAuthService.getAuthTokens();
  return {
    ...headers,
    "Content-Type": "application/json",
    Authorization: authToken,
  };
};
const sendByMethod = async (url, method, data) => {
  const requestOptions = {
    method: method,
    body: JSON.stringify(data || {}),
  };
  const resp = await _verifyResponse(url, requestOptions);
  return await resp.json();
};

const post = async (url, data) => {
  const headers = await createHeaders();
  const resp = await axios.post(url, data, { headers });
  return resp.data || {};
};
const put = async (url, data) => {
  return await sendByMethod(url, "PUT", data);
};
const _delete = async (url, data) => {
  return await sendByMethod(url, "DELETE", data);
};
const get = async (url) => {
  const headers = await createHeaders();
  const resp = await axios.get(url, { headers });
  return resp.data || {};
};

const uploadWithAxios = async (url, formData, progressCallback, headers) => {
  const config = {
    onUploadProgress: progressCallback,
  };
  return await axios.post(url, formData, config);
  // const authToken = await AuthService.auth.currentUser.getIdToken();
  // return await axios.request({
  //     method: method,
  //     url: url,
  //     data: formData,
  //     onUploadProgress: progressCallback
  // });
};
const postFile = async (url, formData, progressCallback) => {
  return uploadWithAxios(url, "POST", formData, progressCallback);
};
const updateFile = async (url, filename, formData, progressCallback) => {
  return uploadWithAxios(url, "PUT", formData, progressCallback, {
    filename: filename,
  });
};
export const RestHelper = {
  post,
  get,
  put,
  delete: _delete,
  postFile,
  putFile: updateFile,
};
