// material
import { Box, Button, Card, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { getDefaultPhoto, getFileUrl } from "src/utils/photoutil";
// utils
import { toAge } from "../../../utils/formatTime";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

Profile.propTypes = {
  product: PropTypes.object,
};

export default function Profile({ user, button }) {
  const [t] = useTranslation("global");
  const { name, birthday, files } = user;
  const photoUrl =
    files?.length > 0
      ? getFileUrl(user.uid, files[0])
      : getDefaultPhoto(user.gender);
  const age = toAge(birthday) || "";

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <ProductImgStyle alt={name} src={photoUrl} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography textAlign={"center"} variant="h5" noWrap>
          {name}, {age}
        </Typography>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Button
            fullWidth
            variant="outlined"
            onClick={() => button.click(user)}
          >
            {button.text}
          </Button>
          <Link
            underline="none"
            component={RouterLink}
            to="/user-info"
            state={{ user: user }}
          >
            <Button fullWidth variant="text">
              {t("likes.view")}
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Card>
  );
}
