import { Navigate, useRoutes } from "react-router-dom";
import { useAuthUser } from "./contexts/UserContext";
import MainLayout, { InitialMainLayout } from "./layouts/main";
import Automatch from "./pages/Automatch";
import Events from "./pages/Events";
import MainApp from "./pages/MainApp";
import Messages from "./pages/Messages";
import MyQuestions from "./pages/MyQuestions";
import Preferences from "./pages/Preferences";
import ProfilePreview from "./pages/ProfilePreview";
import UserLikes from "./pages/UserLikes";
import UserProfile from "./pages/UserProfile";
import ProfileUpdate from "./pages/ProfileUpdate";

// ----------------------------------------------------------------------

export default function Router() {
  const user = useAuthUser();

  const completePrfileChildren = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "explore", element: <MainApp /> },
        { path: "messages", element: <Messages /> },
        { path: "likes", element: <UserLikes /> },
        { path: "preview", element: <ProfilePreview /> },
        { path: "profile/:section", element: <ProfileUpdate /> },
        { path: "profile", element: <ProfileUpdate /> },
        { path: "preferences", element: <Preferences /> },

        { path: "user-info", element: <UserProfile /> },
        { path: "events", element: <Events /> },
        { path: "myquestions", element: <MyQuestions /> },
        { path: "automatch", element: <Automatch /> },
        { path: "/", element: <Navigate to="/explore" /> },
      ],
    },
    { path: "*", element: <Navigate to="/explore" replace /> },
  ];
  const initialProfileChildren = [
    { path: "/", element: <Navigate to="profile/1" replace /> },
    {
      path: "/profile/:section",
      element: <InitialMainLayout />,
      children: [{ path: "profile/:section", element: <ProfileUpdate /> }],
    },
    { path: "*", element: <Navigate to="profile/1" replace /> },
  ];

  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children:
        user.profileStatus === "complete"
          ? completePrfileChildren
          : initialProfileChildren,
    },
  ]);
}
