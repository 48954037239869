import {
  Alert,
  Button,
  ButtonGroup,
  CardContent,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { convertHeight } from "src/utils/formatNumber";
import { UserService } from "../../../services";
import { UserCardSkeleton } from "../UserFeed/UserCardSkeleton";
import { useTranslation } from "react-i18next";
import { i8Helper } from "src/utils/i18Helper";

export default function AboutMeEditor({backCallback}) {
  const [currentUser, setProfile] = useState();
  const [processing, setprocessing] = useState(false);
  const [message, setMessage] = useState();
  const [heightUnit, setHeightUnit] = useState("cm");
  const [t] = useTranslation("global");

  const getUserInfo = async () => {
    const userInfo = await UserService.getUserInfo();

    setProfile(userInfo);
    // if (userInfo.height) {
    //   setHeightCm(userInfo.height);
    //   setHeightFeet(convertHeight(userInfo.height, "cm", "feet"));
    //   setHeightMeters(convertHeight(userInfo.height, "cm", "meter"));
    // }
  };

  const onformSumit = (e) => {
    e.preventDefault();
    updateProfile();
  };
  const onInputChange = (e) => {
    setProfile({
      ...currentUser,
      [e.target.name]: e.target.value,
    });
  };
  const updateProfile = async () => {
    try {
      // currentUser.height = heightCm || "";
      setprocessing(true);
      await UserService.updateProfile({
        section: "aboutInfo",
        data: currentUser,
      });
      
      const msg = {
        type: "success",
        text: t("profile.basicInfo.profileUpdated"),
      };
      setMessage(msg);
      setprocessing(false);
      if (backCallback) {
        backCallback(msg);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // const [heightCm, setHeightCm] = useState("");
  // const [heightFeet, setHeightFeet] = useState("");
  // const [heightMeters, setHeightMeters] = useState("");

  // const handleHeightCmChange = (event) => {
  //   if (event.target.value) {
  //     const val = parseInt(event.target.value);
  //     setHeightCm(val);
  //     setHeightFeet(convertHeight(val, "cm", "feet"));
  //     setHeightMeters(convertHeight(val, "cm", "meter"));
  //   } else {
  //     setHeightCm("");
  //     setHeightFeet("");
  //     setHeightMeters("");
  //   }
  // };

  // const handleHeightFeetChange = (event) => {
  //   const val = event.target.value;
  //   if (val) {
  //     setHeightFeet(val);
  //     setHeightCm(convertHeight(val, "feet", "cm"));
  //     setHeightMeters(convertHeight(val, "feet", "meter"));
  //   } else {
  //     setHeightCm("");
  //     setHeightFeet("");
  //     setHeightMeters("");
  //   }
  // };

  // const handleHeightMetersChange = (event) => {
  //   const val = event.target.value;
  //   if (val) {
  //     setHeightMeters(val);
  //     setHeightCm(convertHeight(val, "meter", "cm"));
  //     setHeightFeet(convertHeight(val, "meter", "feet"));
  //   } else {
  //     setHeightCm("");
  //     setHeightFeet("");
  //     setHeightMeters("");
  //   }
  // };
  const heightRangesObj = t("heightRanges", { returnObjects: true });
  const hieghtRangesList = Object.entries(heightRangesObj);

  return currentUser ? (
    <form autoComplete="off">
      <Stack>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ color: "text.secondary" }}
          >
            {t("profile.aboutMe.aboutMe")}
          </Typography>
          <Stack spacing={3} paddingTop={3}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              type="text"
              label={t("profile.aboutMe.intro")}
              name={"bio"}
              value={currentUser.bio || ""}
              onChange={onInputChange}
            />

            {/* <Stack>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    label={t("profile.aboutMe.heightCm")}
                    type="text"
                    value={heightCm}
                    onChange={handleHeightCmChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("profile.aboutMe.heightFeet")}
                    type="text"
                    value={heightFeet}
                    onChange={handleHeightFeetChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("profile.aboutMe.heightMeter")}
                    type="text"
                    value={heightMeters}
                    onChange={handleHeightMetersChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Stack> */}
            <Stack spacing={3} direction={"row"} alignItems={"center"}>
              <FormControl fullWidth>
                <InputLabel id="height-range-select-label">
                  {t("profile.aboutMe.height")}
                </InputLabel>
                <Select
                  labelId="eight-range-select-label"
                  id="eight-range-select"
                  value={currentUser.heightRange || ""}
                  label={t("profile.aboutMe.height")}
                  name="heightRange"
                  onChange={onInputChange}
                >
                  {hieghtRangesList.map((h) => (
                    <MenuItem key={`${h[0]}`} value={h[0]}>
                      <span>{h[1][heightUnit]}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <ButtonGroup>
                  <Button
                    variant={heightUnit === "cm" ? "contained" : "outlined"}
                    onClick={() => setHeightUnit("cm")}
                  >
                    {t("profile.aboutMe.cm")}
                  </Button>
                  <Button
                    variant={heightUnit === "feet" ? "contained" : "outlined"}
                    onClick={() => setHeightUnit("feet")}
                  >
                    {t("profile.aboutMe.feet")}
                  </Button>
                  <Button
                    variant={heightUnit === "meter" ? "contained" : "outlined"}
                    onClick={() => setHeightUnit("meter")}
                  >
                    {t("profile.aboutMe.meters")}
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Stack>
            <Stack>
              <FormControl fullWidth>
                <InputLabel id="marital-status-select-label">
                  {t("profile.aboutMe.maritalStatus")}
                </InputLabel>
                <Select
                  labelId="marital-status-select-label"
                  id="marital-status-select"
                  value={currentUser.maritalStatus || ""}
                  label={t("profile.aboutMe.maritalStatus")}
                  name="maritalStatus"
                  onChange={onInputChange}
                >
                  <MenuItem value={"single"}>
                    {t("profile.aboutMe.single")}
                  </MenuItem>
                  <MenuItem value={"divorced"}>
                    {t("profile.aboutMe.divorced")}
                  </MenuItem>
                  <MenuItem value={"other"}>
                    {t("profile.aboutMe.other")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("profile.aboutMe.hasChildren")}</Typography>
              <Switch
                checked={currentUser.hasChildren}
                onChange={(e, v) =>
                  onInputChange({ target: { name: "hasChildren", value: v } })
                }
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("profile.aboutMe.willingToRelocate")} </Typography>
              <Switch
                checked={currentUser.canRelocate}
                onChange={(e, v) =>
                  onInputChange({ target: { name: "canRelocate", value: v } })
                }
              />
            </Stack>
            {message && <Alert severity={message.type}>{message.text}</Alert>}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              onClick={onformSumit}
              disabled={processing}
            >
              {t("general.save")}
            </Button>
          </Stack>
        </CardContent>
      </Stack>
    </form>
  ) : (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} sm={6} md={4} flexDirection={"row"}>
        <UserCardSkeleton />
      </Grid>
    </Grid>
  );
}
