import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Box,
  Button
} from "@mui/material";
// component
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { useAuthProvider } from "src/contexts/UserContext";
import UserAuthService from "src/services/UserAuthService";
import Iconify from "../../../components/Iconify";
import { useTranslation } from "react-i18next";
import ForgotPassword from "./ForgotPassword";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const setAuthUser = useAuthProvider();
  const [showPassword, setShowPassword] = useState(false);
  const [showRegisterButon, setshowRegisterButon] = useState(true);
  const [message, setMessage] = useState();
  const LOGIN_VIEW = "login";
  const VERIFY_VIEW = "verify";
  const CHANGE_PASSWORD_VIEW = "change-password";
  const [view, setview] = useState(LOGIN_VIEW);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(""),
    password: Yup.string().required("").min(6),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "",
      remember: true,
    },
    validationSchema: LoginSchema,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const signupHandler = (e) => {
    e?.preventDefault();
    const values = formik.values;

    setMessage(null);
    if (
      formik.errors.email !== undefined ||
      formik.errors.password !== undefined
    ) {
      setMessage({
        type: "error",
        text: t("login.invalidPasswordMessage"),
      });
    } else {
      UserAuthService.userPool.signUp(
        values.email,
        values.password,
        [],
        null,
        (err, data) => {
          if (err && err.code === "InvalidParameterException") {
            setMessage({
              type: "error",
              text: t("login.invalidPasswordMessage"),
            });
          } else if (err && err.code === "UsernameExistsException") {
            setMessage({
              type: "success",
              text: t("login.userAlreadyRegisterd"),
            });
            setshowRegisterButon(false);
          } else {
            setview(VERIFY_VIEW);
          }
        }
      );
    }
  };

  const resendCodeHandler = async (e) => {
    e?.preventDefault();
    if (formik.isValid) {
      const { email } = formik.values;
      const user = new CognitoUser({
        Username: email,
        Pool: UserAuthService.userPool,
      });
      setMessage(null);
      user.resendConfirmationCode((err) => {
        if (err) {
          setMessage({
            type: "error",
            text: t("login.newCodeSendError"),
          });
        } else {
          setMessage({
            type: "success",
            text: t("login.newCodeSent"),
          });
        }
      });
    }
  };

  const verfiyCodeHandler = (e) => {
    e.preventDefault();
    const { email, code } = formik.values;
    if (formik.isValid && code) {
      const user = new CognitoUser({
        Username: email,
        Pool: UserAuthService.userPool,
      });
      user.confirmRegistration(code, true, function (err, result) {
        if (err) {
          if (err.code === "CodeMismatchException") {
            setMessage({
              type: "error",
              text: t("login.invalidVerificationCode"),
            });
          } else {
            setMessage({
              type: "error",
              text: t("login.completeRegistrationError"),
            });
          }
        } else {
          signIn();
        }
      });
    }
  };

  const signIn = () => {
    if (formik.isValid) {
      const { email, password } = formik.values;
      const user = new CognitoUser({
        Username: email,
        Pool: UserAuthService.userPool,
      });
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
      setMessage(null);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setAuthUser(data);
        },
        onFailure: (err) => {
          if (err.code === "UserNotConfirmedException") {
            setview(VERIFY_VIEW);
          } else if (err.code === "NotAuthorizedException") {
            setMessage({
              type: "error",
              text: t("login.wrongCredentialsMessage"),
            });
          } else if (err.code === "UserNotFoundException") {
            setMessage({
              type: "error",
              text: t("login.wrongCredentialsMessage"),
            });
          }
        },
      });
    } else {
      setMessage({
        type: "error",
        text: t("login.wrongCredentialsMessage"),
      });
    }
  };
  const onSignIn = (e) => {
    e?.preventDefault();
    signIn();
  };

  const [t] = useTranslation("global");

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        {view !== CHANGE_PASSWORD_VIEW && (
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t("login.email")}
              {...getFieldProps("email")}
              error={Boolean(message || (touched.email && errors.email))}
              helperText={touched.email && errors.email}
            />

            {view === VERIFY_VIEW ? (
              <TextField
                fullWidth
                autoComplete="code"
                label={t("login.emailCode")}
                {...getFieldProps("code")}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />
            ) : (
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label={t("login.password")}
                {...getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            )}

            {message && <Alert severity={message.type}>{message.text}</Alert>}
          </Stack>
        )}

        {view == LOGIN_VIEW ? (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps("remember")}
                    checked={values.remember}
                  />
                }
                label={t("login.rememberMe")}
              />

              <Link
                component={RouterLink}
                variant="subtitle2"
                to="#"
                underline="hover"
                onClick={() => setview(CHANGE_PASSWORD_VIEW)}
              >
                {t("login.forgotPasswordQuestion")}
              </Link>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Button
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
                onClick={() => onSignIn()}
              >
                {t("login.login")}
              </Button>

              {showRegisterButon && (
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  // type="submit"
                  variant="contained"
                  error={message}
                  disabled={!message && isSubmitting}
                  onClick={signupHandler}
                >
                  {t("login.register")}
                </Button>
              )}
            </Stack>
          </Box>
        ) : view === VERIFY_VIEW ? (
          <Box>
            <Stack direction={"row"} spacing={2} padding={2}>
              <Button
                fullWidth
                color="primary"
                size="large"
                // type="submit"
                variant="contained"
                onClick={resendCodeHandler}
              >
                {t("login.resendCode")}
              </Button>
              <Button
                fullWidth
                color="primary"
                size="large"
                variant="contained"
                onClick={verfiyCodeHandler}
                disabled={!formik.values.code}
              >
                {t("login.completeRegistration")}
              </Button>
            </Stack>
            <Stack alignItems={"center"}>
              <Link
                component={RouterLink}
                variant="subtitle1"
                to="#"
                underline="hover"
                onClick={() => setview(LOGIN_VIEW)}
              >
                {t("general.back")}
              </Link>
            </Stack>
          </Box>
        ) : (
          <ForgotPassword
            email={formik.values.email}
            callback={() => setview(LOGIN_VIEW)}
          />
        )}
      </Form>
    </FormikProvider>
  );
}
