import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { UserService } from "src/services";
import Iconify from "../components/Iconify";
// components
import Page from "../components/Page";

export default function MyQuestions() {
  const QUESTION_OPTIONS = 0,
    QUESTION_ONE = 1,
    QUESTION_TWO = 2,
    QUESTION_THREE = 3;

  const [showSnackbar, setshowSnackbar] = useState(false);
  const [showView, setShowView] = useState(QUESTION_OPTIONS);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowSnackbar(false);
  };

  const showFilterOptions = () => {
    setShowView(QUESTION_OPTIONS);
  };
  const questions = [
    { id: 1, title: "Question 1", view: QUESTION_ONE },
    { id: 2, title: "Question 2", view: QUESTION_TWO },
    { id: 3, title: "Question 3", view: QUESTION_THREE },
  ];
  const Question = ({ queestion }) => {
    const [questionType, setQuestionType] = useState("text");
    const [choices, setchoices] = useState([]);
    const [choice, setchoice] = useState("");
    const [questionText, setquestionText] = useState("");
    const addChoice = () => {
      const val = choice.trim();
      if (val && choices.length < 3) {
        setchoices((prev) => [...prev, val]);
        setchoice("");
      }
    };
    const saveQuestion = async () => {
      const newQuestion = {
        type: questionType,
        no: queestion.id,
        text: questionText,
        choices: choices.splice(0, 3),
      };
      await UserService.updateQuestions([newQuestion]);
      setShowView(QUESTION_OPTIONS);
    };
    return (
      <Card>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingBottom={1}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <IconButton onClick={() => setShowView(QUESTION_OPTIONS)}>
              <Iconify
                icon="eva:arrow-ios-back-fill"
                sx={{ width: 40, height: 40 }}
              />
            </IconButton>
            <Typography>{queestion.title}</Typography>
          </Stack>
        </Stack>
        <Divider />
        <CardContent>
          <Stack spacing={1} gap={1}>
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <FormLabel id="question-group-label">Question type</FormLabel>

              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="questions-row-radio-buttons-group-label"
                  name="question-type-group"
                >
                  <FormControlLabel
                    value="text"
                    control={
                      <Radio
                        checked={!questionType || questionType == "text"}
                      />
                    }
                    label="Text"
                    onClick={() => setQuestionType("text")}
                  />
                  <FormControlLabel
                    value="choice"
                    control={<Radio checked={questionType == "choices"} />}
                    label="Choice"
                    onClick={() => setQuestionType("choices")}
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            <TextField
              required
              autoComplete="off"
              placeholder="Type you question here"
              value={questionText}
              onChange={(e) => setquestionText(e.target.value)}
            />

            {questionType === "choices" && (
              <Stack spacing={1}>
                <List>
                  {choices.map((ch) => (
                    <ListItem>
                      <ListItemText disableTypography primary={ch} />
                      <IconButton
                        onClick={() => {
                          setchoices((prev) => prev.filter((p) => p !== ch));
                        }}
                      >
                        <Iconify icon={"ant-design:delete-filled"} />
                      </IconButton>
                      <Divider />
                    </ListItem>
                  ))}
                </List>
                {choices.length < 3 && (
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      size="small"
                      value={choice}
                      onChange={(e, v) => setchoice(e.target.value)}
                    />
                    <Button
                      padding={2}
                      variant="outlined"
                      size="small"
                      onClick={addChoice}
                    >
                      Add
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
            <Typography color={"GrayText"} variant={"h6"} textAlign={"center"}>
              Anyone who likes you will be asked this question
            </Typography>
          </Stack>
        </CardContent>
        <CardActions>
          <Button fullWidth onClick={saveQuestion}>
            Save
          </Button>
        </CardActions>
      </Card>
    );
  };
  return (
    <Page>
      <Container maxWidth="xl">
        <Snackbar
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Your prefences has been updated.
          </Alert>
        </Snackbar>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={8}>
            {showView === QUESTION_OPTIONS && (
              <Card>
                <Typography
                  textAlign={"center"}
                  color={"GrayText"}
                  paddingTop={5}
                >
                  You got questions for the people who like you?
                </Typography>
                <Typography textAlign={"center"} color={"GrayText"}>
                  Ask them here
                </Typography>
                <CardContent>
                  {questions.map((option) => (
                    <ListItem key={`${option.id}`} style={{ padding: 5 }}>
                      <ListItemButton onClick={() => setShowView(option.view)}>
                        <Iconify icon={option.icon} height={30} width={30} />
                        <Stack
                          direction={"row"}
                          flex={1}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          paddingLeft={1}
                        >
                          <Typography variant="h6">{option.title}</Typography>
                          <Iconify
                            icon={"akar-icons:chevron-right"}
                            height={30}
                            width={30}
                          />
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </CardContent>
              </Card>
            )}
            {showView === QUESTION_ONE && <Question queestion={questions[0]} />}
            {showView === QUESTION_TWO && <Question queestion={questions[1]} />}
            {showView === QUESTION_THREE && (
              <Question queestion={questions[2]} />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
