import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
// material
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Iconify from "./Iconify";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------
export const MOBILE_NAVBAR_HEIGHT = "80px";
export default function MobileNavbar({ onOpenSidebar, ...other }) {
  const [t] = useTranslation("global");
  const [value, setValue] = useState(0);

  return (
    <Box
      {...other}
      style={{
        position: "fixed",
        height: MOBILE_NAVBAR_HEIGHT,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#fff",
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          // label={t("menu.explore")}
          icon={<Iconify icon="fa-brands:slack-hash" width={25} height={25} />}
          style={{ backgroundColor: "#fff" }}
          component={Link}
          to="/explore"
        />
        <BottomNavigationAction
          // label={t("menu.likes")}
          icon={
            <Iconify icon="ant-design:heart-filled" width={25} height={25} />
          }
          style={{ backgroundColor: "#fff" }}
          component={Link}
          to="/likes"
        />
        <BottomNavigationAction
          // label={t("menu.messages")}
          icon={
            <Iconify icon="bi:chat-left-heart-fill" width={25} height={25} />
          }
          style={{ backgroundColor: "#fff" }}
          component={Link}
          to="/messages"
        />
        <BottomNavigationAction
          // label={t("menu.preferences")}
          icon={<Iconify icon="ri:user-fill" width={25} height={25} />}
          style={{ backgroundColor: "#fff" }}
          onClick={() => onOpenSidebar()}
        />
      </BottomNavigation>
    </Box>
  );
}
