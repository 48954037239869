import {
  Avatar,
  Box,
  Drawer,
  Link,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
// material
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Iconify from "../../components/Iconify";
// components
import NavSection from "../../components/NavSection";
import Scrollbar from "../../components/Scrollbar";
import { useAuthProvider, useAuthUser } from "../../contexts/UserContext";
// hooks
import UserAuthService from "src/services/UserAuthService";
import useResponsive from "../../hooks/useResponsive";
import LanguageSelector from "src/sections/@language/LanguageSelector";
import { FeedService } from "src/services";
import { getDefaultPhoto, getFileUrl } from "src/utils/photoutil";
import { useTranslation } from "react-i18next";
import { navConfig, mobileNavConfig } from "./NavConfig";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 264; //280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    // width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

MainSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function MainSidebar({ isOpenSidebar, onCloseSidebar }) {
  const [userInfo, setuserInfo] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  const setAuthUser = useAuthProvider();
  const loggedInUser = useAuthUser();
  const [t] = useTranslation("global");

  const logout = async () => {
    // await AuthService.signOutGoogle();
    await UserAuthService.signout();
    setAuthUser(null);
  };

  const getUserInfo = async () => {
    const response = await FeedService.getFeedProfiles([loggedInUser.uid]);
    if (response.length > 0) {
      const u = response[0];
      setuserInfo(u);
      const photo =
        u.files?.length > 0
          ? getFileUrl(u.uid, u.files[0])
          : getDefaultPhoto(u.gender);
      setPhotoUrl(photo);
    }
  };
  const usr = useAuthUser();
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    getUserInfo();
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <div className="logo-heart-container">
          <Iconify
            icon={"ant-design:heart-filled"}
            color={"#ee224b"}
            height={80}
            width={80}
            style={{ margin: 0, transform: [{ rotate: "30deg" }] }}
          />
          <Iconify
            icon={"ant-design:heart-filled"}
            color={"#ee224b"}
            height={80}
            width={80}
            style={{ marginLeft: -40, transform: [{ rotate: "30deg" }] }}
          />
        </div>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link
          underline="none"
          component={RouterLink}
          to="/preview"
          title={usr.email}
        >
          <AccountStyle>
            <Avatar src={photoUrl} alt={userInfo?.name} />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {userInfo?.name}
              </Typography>
            </Box>
            <Stack direction={"row"} flex={1} justifyContent={"right"}>
              <Iconify
                icon={"akar-icons:chevron-right"}
                height={22}
                width={22}
              />
            </Stack>
          </AccountStyle>
        </Link>
      </Box>
      <Box></Box>
      {loggedInUser.profileStatus === "complete" && (
        <NavSection
          sx={{ display: { xs: "none", lg: "block" } }}
          navConfig={navConfig}
        />
      )}
      {loggedInUser.profileStatus === "complete" && (
        <NavSection
          sx={{ display: { xs: "block", lg: "none" } }}
          navConfig={mobileNavConfig}
        />
      )}
      <Box>
        <ListItemButton onClick={logout}>
          <ListItemAvatar>
            <Iconify
              icon={"clarity:logout-solid"}
              sx={{ width: 22, height: 22, ml: 1 }}
            />
          </ListItemAvatar>
          <ListItemText primary={t("menu.logout")} />
        </ListItemButton>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <LanguageSelector />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
