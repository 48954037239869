import { ConfigManager } from "./config/ConfigManager";
import { RestHelper } from "./RestHelper";

const baseUrl = ConfigManager.getUrl("userPreferences");

const updatePreferences = async (data) => {
  return await RestHelper.post(`${baseUrl}user-preferences`, data);
};

const getUserPreferences = async () => {
  const result = await RestHelper.get(`${baseUrl}user-preferences`);
  return result;
};

export const PreferencesService = {
  getUserPreferences,
  updatePreferences,
};
