// import { replace } from "lodash";
// import numeral from "numeral";

// // ----------------------------------------------------------------------

// export function fCurrency(number) {
//   return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
// }

// export function fPercent(number) {
//   return numeral(number / 100).format("0.0%");
// }

// export function fNumber(number) {
//   return numeral(number).format();
// }

// export function fShortenNumber(number) {
//   return replace(numeral(number).format("0.00a"), ".00", "");
// }

// export function fData(number) {
//   return numeral(number).format("0.0 b");
// }

export function convertHeight(value, fromUnit, toUnit) {
  if (value) {
    switch (fromUnit) {
    case "cm":
      switch (toUnit) {
      case "feet":
        return (value / 30.48).toFixed(2);
      case "meter":
        return (value / 100).toFixed(2);
      default:
        break;
      }
      break;
    case "feet":
      switch (toUnit) {
      case "cm":
        return parseInt(value * 30.48);
      case "meter":
        return (value * 0.3048).toFixed(2);
      default:
        break;
      }
      break;
    case "meter":
      switch (toUnit) {
      case "cm":
        return parseInt(value * 100);
      case "feet":
        return (value / 0.3048).toFixed(2);
      default:
        break;
      }
      break;
    default:
      break;
    }
  }
  return undefined;
}
