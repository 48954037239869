import { ConfigManager } from "./config/ConfigManager";
import { RestHelper } from "./RestHelper";
import { UserFilesService } from "./UserFilesService";
const feedBaseUrl = ConfigManager.getUrl("userFeeds");
const usersBaseUrl = ConfigManager.getUrl("users");
const getFeed = async () => {
  const response = await RestHelper.get(`${feedBaseUrl}user-feed`);
  return response || [];
};

const getFeedProfiles = async (uids) => {
  if (!uids || uids.length === 0) {
    return [];
  }
  const response = await RestHelper.post(`${usersBaseUrl}profiles`, {
    uid: uids,
  });
  if (response?.users?.length > 0) {
    const profiles = response.users.map((user) => {
      user.files = response?.files[user.uid];
      return user;
    });
    return profiles;
  }
  return [];
};

export const FeedService = {
  getFeed,
  getFeedProfiles,
};
