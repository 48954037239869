import { TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ConfigManager } from "src/services/config/ConfigManager";

let autoComplete;
const mapsKay = ConfigManager.getValue("mapsApiKey");
const loadScript = (url, callback) => {
  const isScriptAdded = document.getElementById("autocompleteLocationScript");

  let script = isScriptAdded || document.createElement("script");
  script.type = "text/javascript";
  script.id = "autocompleteLocationScript";
  if (isScriptAdded) {
    callback();
  } else if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url;
  if (!isScriptAdded) {
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["(cities)"] }
  );
  autoComplete.setFields([
    "place_id",
    "address_component",
    "formatted_address",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  updateQuery(addressObject);
}

function LocationAutoComplete({ onPlaceSelected, stateValue, label }) {
  const [query, setQuery] = stateValue;
  const autoCompleteRef = useRef(null);

  const notifySelected = (addressObject) => {
    const formattedAddress = addressObject.formatted_address;
    setQuery(formattedAddress);
    onPlaceSelected(addressObject);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${mapsKay}&libraries=geometry,places`,
      () => handleScriptLoad(notifySelected, autoCompleteRef)
    );
  }, []);

  return (
    <TextField
      fullWidth
      type="text"
      label={label}
      inputRef={autoCompleteRef}
      onChange={(event) => setQuery(event.target.value)}
      value={query}
    />
  );
}

export default LocationAutoComplete;
