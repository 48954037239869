import { Box, Card, CardActions, CardContent, Skeleton, Stack } from '@mui/material';

export function UserCardSkeleton() {
    return (
        <Card>
            <Skeleton variant="rectangular" height={300} />
            <CardContent>
                <Stack direction="row" alignItems="center" gap={1} style={{ paddingBottom: 5 }}>
                    <Skeleton variant="text" style={{ width: '100%' }} />
                    <Skeleton variant="text" style={{ width: '100%' }} />
                </Stack>

                <Box gap={1}>
                    <Stack alignItems="left" gap={1} style={{ paddingBottom: 5 }}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} style={{ paddingBottom: 5 }}>
                        <Skeleton variant="text" style={{ width: '100%' }} />
                        <Skeleton variant="text" style={{ width: '100%' }} />
                    </Stack>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }} >
                <Skeleton variant="circular" width={50} height={50} />
                <Skeleton variant="circular" width={50} height={50} />
            </CardActions>
        </Card>
    );
}
export function ContentSkeleton() {
    return (
        <Card>
            <CardContent>
                <Stack direction="row" alignItems="center" gap={1} style={{ paddingBottom: 5 }}>
                    <Skeleton variant="text" style={{ width: '100%' }} />
                    <Skeleton variant="text" style={{ width: '100%' }} />
                </Stack>

                <Box gap={1}>
                    <Stack alignItems="left" gap={1} style={{ paddingBottom: 5 }}>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1} style={{ paddingBottom: 5 }}>
                        <Skeleton variant="text" style={{ width: '100%' }} />
                        <Skeleton variant="text" style={{ width: '100%' }} />
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    );
}
