/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: MessageInput!) {
    createMessage(input: $input) {
      conversationId
      createdAt
      text
      owner
      __typename
    }
  }
`;
export const unmatchUser = /* GraphQL */ `
  mutation UnmatchUser($conversationId: ID!, $matchUid: ID!) {
    unmatchUser(conversationId: $conversationId, matchUid: $matchUid)
  }
`;
