// material
import { useEffect, useState } from "react";
import { FeedService, LikeService } from "../../../services";
import UserList from "./UserList";
import { useTranslation } from "react-i18next";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";

// ----------------------------------------------------------------------

export default function LikedList() {
  const [users, setUsers] = useState();
  const [t] = useTranslation("global");
  const getLikedUsers = async () => {
    const resp = await LikeService.getLiked();
    const uids = resp.map((r) => r.uid);
    const profiles = await FeedService.getFeedProfiles(uids);
    setUsers(profiles);
  };

  const passUser = async (user) => {
    await LikeService.sendPass([user.uid]);
    const remaining = users.filter((u) => u != user);
    setUsers(remaining);
  };

  useEffect(() => {
    getLikedUsers();
  }, []);

  return !users ? (
    <ContentSkeleton />
  ) : (
    <UserList
      users={users}
      button={{ click: passUser, text: t("likes.pass") }}
    />
  );
}
