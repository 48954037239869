import { ConfigManager } from "src/services/config/ConfigManager";

export function getDefaultPhoto(gender) {
  return gender === "female"
    ? "/default/default_female_user.jpeg"
    : "/default/defaule_male_user.webp";
}
export function getFileUrl(uid, file) {
  const picsDomain = ConfigManager.getUrl("cdnFiles");
  return `${picsDomain}${uid}/${file.fileName}.${file.type}`;
}
