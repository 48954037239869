import {
  Alert,
  Autocomplete,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import { UserService } from "../../../services";
import { UserCardSkeleton } from "../UserFeed/UserCardSkeleton";
import { useTranslation } from "react-i18next";

export default function HobbiesAndLanguagesEditor({backCallback}) {
  const [user, setProfile] = useState();
  const [processing, setprocessing] = useState(false);
  const [message, setMessage] = useState();
  const [t] = useTranslation("global");

  const languages = t("languages", { returnObjects: true });
  const languagesValues = Object.keys(t("languages", { returnObjects: true }))
    .map((key) => languages[key])
    .sort();

  const getLanguageKeyFromValue = (languageValue) => {
    const found = Object.keys(languages).find(
      (key) => languages[key] === languageValue
    );

    return found || "";
  };

  const getUserInfo = async () => {
    const userInfo = await UserService.getUserInfo();
    setProfile(userInfo);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const onformSumit = (e) => {
    e.preventDefault();
    updateProfile();
  };
  const onHobiesChanged = (e) => {
    setProfile({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const onLanguagesChanged = (e) => {
    setProfile({
      ...user,
      [e.target.name]: e.target.value.map((l) => getLanguageKeyFromValue(l)),
    });
  };

  const updateProfile = async () => {
    try {
      setprocessing(true);
      await UserService.updateProfile({ section: "otherInfo", data: user });
    
      const msg = {
        type: "success",
        text: t("profile.basicInfo.profileUpdated"),
      };
      setMessage(msg);
      setprocessing(false);
      if (backCallback) {
        backCallback(msg);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  return user ? (
    <form autoComplete="off">
      <Stack>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ color: "text.secondary" }}
          >
            {t("profile.hobbiesAndLanguages.hobbiesAndLanguages")}
          </Typography>

          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            value={user.hobbies || []}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("profile.hobbiesAndLanguages.hobbies")}
              />
            )}
            onChange={(e, v) =>
              onHobiesChanged({ target: { name: "hobbies", value: v } })
            }
          />
          <Divider />

          <Stack spacing={3} marginTop={3}>
            <Autocomplete
              multiple
              id="languages-tags-filled"
              options={languagesValues}
              value={user.languages?.map((l) => languages[l]) || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("profile.hobbiesAndLanguages.languages")}
                />
              )}
              onChange={(e, v) =>
                onLanguagesChanged({ target: { name: "languages", value: v } })
              }
            />

            {message && <Alert severity={message.type}>{message.text}</Alert>}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onformSumit}
              disabled={processing}
            >
              {t("general.save")}
            </Button>
          </Stack>
        </CardContent>
      </Stack>
    </form>
  ) : (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} sm={6} md={4} flexDirection={"row"}>
        <UserCardSkeleton />
      </Grid>
    </Grid>
  );
}
