import { Stack, Card, ListItemAvatar, Avatar } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect, useState } from "react";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import { ContentSkeleton } from "../@main/UserFeed/UserCardSkeleton";
import { gql, useQuery } from "@apollo/client";
import { listConversations } from "src/graphql/queries";
import { useTranslation } from "react-i18next";
import { UserService } from "src/services";
import { getFileUrl } from "src/utils/photoutil";

export default function UserConversations({ conversationClickCallback }) {
  const [t] = useTranslation("global");
  const { data, loading, error } = useQuery(gql(listConversations));
  const conversations = data?.listConversations?.items;
  const [basicInfos, setbasicInfos] = useState({});
  const getBasicInfo = async (uids) => {
    const basicInfosResponse = await UserService.getUsersBasicInfo(uids);
    const newInfos = {};
    basicInfosResponse?.forEach((info) => {
      info.photoUrl = getPhoto(info.uid, info.files);
      newInfos[info.uid] = info;
    });
    setbasicInfos(newInfos);
  };

  const getPhoto = (uid, files) => {
    if (files?.length > 0) {
      const photoUrl = getFileUrl(uid, files[0]);
      return photoUrl;
    }
    return "";
  };
  useEffect(() => {
    if (conversations) {
      var uids = conversations.map((c) => c.matchUid);
      getBasicInfo(uids);
    }
  }, [conversations]);

  return (
    <Scrollbar
      sx={{
        "& .simplebar-content": {
          // height: "75%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {loading && <ContentSkeleton />}
      {!loading && (!conversations || conversations.length === 0) ? (
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4 style={{ textAlign: "center", color: "gray" }}>
            {t("messages.noMessages1")}
          </h4>
          <h3 style={{ textAlign: "center", color: "gray" }}>
            {t("messages.noMessages2")}
          </h3>
        </Stack>
      ) : (
        <List>
          {conversations?.map((conversation) => {
            return (
              <Card
                key={`crd-${conversation.conversationId}`}
                style={{ margin: 2 }}
              >
                <ListItemButton
                  key={`li-${conversation.conversationId}`}
                  onClick={() => {
                    conversationClickCallback(
                      conversation,
                      basicInfos[conversation.matchUid]
                    );
                  }}
                  style={{
                    justifyContent: "space-between",
                    // borderBottom: "1px solid rgb(211, 211, 211)",
                    padding: "10px 0px",
                    boxSizing: "border-box",
                    margin: "0 20px",
                  }}
                >
                  <Stack direction={"row"} alignItems="center">
                    <ListItemAvatar>
                      <Avatar
                        src={getPhoto(
                          conversation.matchUid,
                          basicInfos[conversation.matchUid]?.files
                        )}
                      />
                    </ListItemAvatar>
                    <h4 varient={"h3"}>
                      {basicInfos[conversation.matchUid]?.name}{" "}
                    </h4>
                  </Stack>
                  <Iconify
                    icon={"akar-icons:chevron-right"}
                    height={30}
                    width={30}
                  />
                </ListItemButton>
              </Card>
            );
          })}
        </List>
      )}
    </Scrollbar>
  );
}
