import React, { useState } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  Alert,
  IconButton,
  Stack,
  Link,
  Card,
} from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserAuthService from "src/services/UserAuthService";
import { useTranslation } from "react-i18next";
import Iconify from "../../../components/Iconify";
import { Link as RouterLink } from "react-router-dom";
const ForgotPassword = ({ callback, email }) => {
  const [userEmail, setUserEmail] = useState(email);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState();
  const [sendCodeMessage, setsendCodeMessage] = useState();
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = useState(false);
  const START_VIEW = 1;
  const FINISH_VIEW = 2;
  const [view, setview] = useState(START_VIEW);

  const handleForgotPassword = () => {
    const user = new CognitoUser({
      Username: userEmail,
      Pool: UserAuthService.userPool,
    });
    user.forgotPassword({
      onSuccess: (data) => {
        setMessage({
          type: "success",
          text: t("login.forgotPasswordMessage"),
        });
      },
      onFailure: (err) => {
        if (err.code === "UserNotFoundException") {
          setsendCodeMessage({
            type: "error",
            text: t("login.emailNotRegistered"),
          });
        } else {
          setsendCodeMessage({
            type: "error",
            text: t("login.forgotPasswordError"),
          });
        }
      },
      inputVerificationCode: (data) => {
        setsendCodeMessage({
          type: "success",
          text: t("login.forgotPasswordMessage"),
        });
      },
    });
  };

  const handleResetPassword = () => {
    const userData = {
      Username: userEmail,
      Pool: UserAuthService.userPool,
    };
    if (confirmationCode.length < 6) {
      setMessage({
        type: "error",
        text: t("login.invalidVerificationCode"),
      });
    } else if (newPassword.length < 6) {
      setMessage({
        type: "error",
        text: t("login.invalidPasswordMessage"),
      });
    } else {
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(confirmationCode, newPassword, {
        onSuccess: () => {
          setMessage({
            type: "success",
            text: t("login.passwordUpdated"),
          });
          setview(FINISH_VIEW);
        },
        onFailure: (error) => {
          setMessage({
            type: "error",
            text: t("login.invalidVerificationCode"),
          });
        },
      });
    }
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <Stack spacing={3}>
      {view === START_VIEW && (
        <Stack spacing={3}>
          <Card style={{ padding: "20px" }}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="email"
                label="Email"
                variant="outlined"
                margin="normal"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              {sendCodeMessage && (
                <Alert severity={sendCodeMessage.type}>
                  {sendCodeMessage.text}
                </Alert>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleForgotPassword}
                disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)}
              >
                {t("login.sendVerificationCode")}
              </Button>
            </Stack>
          </Card>

          <Card style={{ padding: "20px" }}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                label={t("login.emailCode")}
                variant="outlined"
                margin="normal"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                autoComplete="off"
                type="number"
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label={t("login.newPassword")}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {message && <Alert severity={message.type}>{message.text}</Alert>}
              <Button
                variant="contained"
                color="primary"
                onClick={handleResetPassword}
              >
                {t("login.updatePassword")}
              </Button>
              <Stack alignItems={"center"}>
                <Link
                  component={RouterLink}
                  variant="subtitle1"
                  to="#"
                  underline="hover"
                  onClick={callback}
                >
                  {t("general.back")}
                </Link>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      )}
      {view === FINISH_VIEW && (
        <Stack>
          {message && <Alert severity={message.type}>{message.text}</Alert>}
          <Button variant="contained" color="primary" onClick={callback}>
            {t("login.login")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default ForgotPassword;
