import {
  Card,
  CardContent,
  ListItem,
  ListItemButton,
  Stack,
  Container,
  Grid,
  Typography,
  Alert,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useEffect } from "react";
import Page from "src/components/Page";
import AboutMeEditor from "src/sections/@main/UserPreview/AboutMeEditor";
import Iconify from "src/components/Iconify";
import BasicInfoEditor from "src/sections/@main/UserPreview/BasicInfoEditor";
import ReligiosityEditor from "src/sections/@main/UserPreview/ReligiosityEditor";
import HobbiesAndLanguagesEditor from "src/sections/@main/UserPreview/HobbiesAndLanguagesEditor";
import PhotoGallery from "src/sections/@main/UserPreview/PhotoGallery";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function ProfileUpdator() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const SECTIONS = 0,
    BASIC_INFO = 1,
    ABOUT = 2,
    RELIGIOSITY = 3,
    HOBBIES = 4,
    PHOTOS = 5;
  const { section: pathSection } = useParams();
  const [showView, setShowView] = useState(SECTIONS);
  const [message, setMessage] = useState();
  const showSections = () => {
    navigate("/profile/0", { replace: true });
    setShowView(SECTIONS);
  };

  const onSectionSaved = (message) => {
    navigate("/profile/0", { replace: true });
    setShowView(SECTIONS);
    setMessage(message);
  };

  const sections = [
    {
      view: BASIC_INFO,
      icon: "bxs:user",
      label: t("profile.basicInfo.basicInfo"),
    },
    {
      view: ABOUT,
      icon: "bxs:user-detail",
      label: t("profile.aboutMe.aboutMe"),
    },
    {
      view: RELIGIOSITY,
      icon: "fa-solid:mosque",
      label: t("profile.religiosity.religiosity"),
    },
    {
      view: HOBBIES,
      icon: "lucide:languages",
      label: t("profile.hobbiesAndLanguages.hobbiesAndLanguages"),
    },
    {
      view: PHOTOS,
      icon: "fluent:camera-add-20-filled",
      label: t("profile.photos.photos"),
    },
  ];
  useEffect(() => {
    if (pathSection > "0" && pathSection < "6") {
      setShowView(parseInt(pathSection));
    }
  }, [showView]);

  return (
    <Page>
      <Container maxWidth="xl">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              {showView === SECTIONS && (
                <Card>
                  <CardContent>
                    {message && (
                      <Alert variant="filled" severity={message.type}>
                        {message.text}
                      </Alert>
                    )}

                    {sections.map((option) => (
                      <ListItem key={`${option.label}`} style={{ padding: 5 }}>
                        <ListItemButton
                          onClick={() => setShowView(option.view)}
                        >
                          <Iconify icon={option.icon} height={30} width={30} />
                          <Stack
                            direction={"row"}
                            flex={1}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            paddingLeft={1}
                          >
                            <Typography variant="h6">{option.label}</Typography>
                            <Iconify
                              icon={"akar-icons:chevron-right"}
                              height={30}
                              width={30}
                            />
                          </Stack>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </CardContent>
                </Card>
              )}

              {showView !== SECTIONS && (
                <Card style={{ paddingBottom: 20 }}>
                  {showView === BASIC_INFO && (
                    <BasicInfoEditor backCallback={onSectionSaved} />
                  )}
                  {showView === ABOUT && (
                    <AboutMeEditor backCallback={onSectionSaved} />
                  )}
                  {showView === RELIGIOSITY && (
                    <ReligiosityEditor backCallback={onSectionSaved} />
                  )}
                  {showView === HOBBIES && (
                    <HobbiesAndLanguagesEditor backCallback={onSectionSaved} />
                  )}
                  {showView === PHOTOS && <PhotoGallery />}
                  <div style={{ backgroundColor: "#fff", padding: 5 }}>
                    <Button
                      color="error"
                      fullWidth
                      onClick={() => showSections()}
                    >
                      {t("general.back")}
                    </Button>
                  </div>
                </Card>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
