// material
import { Grid } from "@mui/material";
import Profile from "./ProfileCard";

// ----------------------------------------------------------------------

export default function UserList({ users, button }) {
  return (
    <Grid container spacing={3}>
      {users?.map((user) => (
        <Grid key={`${user.uid}`} item xs={12} sm={6} md={3}>
          <Profile user={user} button={button} />
        </Grid>
      ))}
    </Grid>
  );
}
