import { Alert, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import AutoCompleteFilter from "./AutoCompleteFilter";
import { useTranslation } from "react-i18next";
import { i8Helper } from "src/utils/i18Helper";

const ProfessionFilter = ({ toggleView }) => {
  const [t] = useTranslation("global");
  const [preferences, setpreferences] = useState();
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);

  const professions = t("professions", { returnObjects: true });
  const professionList = i8Helper.objectToList(professions);

  const saveLocationFilter = async () => {
    try {
      setprocessing(true);
      const professionFilter = {
        section: "professions",
        profession: preferences.profession,
      };
      await PreferencesService.updatePreferences(professionFilter);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };
  const updateProfessions = (selectedValue) => {
    const codes = selectedValue?.map((c) => c.value);
    const p = { ...preferences, profession: codes };
    setpreferences(p);
  };

  const getProfessions = (professions) => {
    if (professions?.length > 0) {
      return professionList?.filter((c) => professions?.includes(c.value));
    }
    return [];
  };
  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"}>
      <AutoCompleteFilter
        data={professionList}
        placeholder={t("preferences.professions")}
        selection={getProfessions(preferences?.profession)}
        onChange={(v, v2) => updateProfessions(v2)}
      />

      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveLocationFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};
export default ProfessionFilter;
