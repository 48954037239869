import {
  Alert,
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  LinearProgress,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import RenderMedia from "src/components/RenderMedia";
import Iconify from "../../../components/Iconify";
import { UserFilesService } from "../../../services/UserFilesService";
import { UserCardSkeleton } from "../UserFeed/UserCardSkeleton";
import { useTranslation } from "react-i18next";
import "./PhotoGallery.css";
import imageCompression from "browser-image-compression";

export default function PhotoGallery() {
  const [newImage, setNewImage] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [userFiles, setUserFiles] = useState();
  const [loading, setLoading] = useState(false);
  const [fileToUpdate, setFileToUpdate] = useState();
  const [uploadProgress, setuploadProgress] = useState(0);
  const [uploadProgressLabel, setuploadProgressLabel] = useState("");
  const [uploading, setuploading] = useState(true);
  const [message, setmessage] = useState();
  const [t] = useTranslation("global");

  const onImageChange = (e) => {
    setFileToUpdate("");
    setmessage(null);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      prepareToUpload(file);
      // setNewImage(file);
      // setNewFile({ photoUrl: URL.createObjectURL(e.target.files[0]) });
      // if ((file.size / (1024 * 1024)).toFixed(2) > 10) {
      //   setmessage({
      //     type: "error",
      //     text: t("files.sizeLimitError"),
      //   });
      //   setuploading(true);
      // } else {
      //   setuploading(false);
      // }
    } else {
      clearFile();
    }
  };
  const prepareToUpload = async (file) => {
    const options = {
      maxSizeMB: 5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressed = await imageCompression(file, options);
    setNewImage(compressed);
    setNewFile({ photoUrl: URL.createObjectURL(compressed) });
    if ((compressed / (1024 * 1024)).toFixed(2) > 5) {
      setmessage({
        type: "error",
        text: t("files.sizeLimitError"),
      });
      setuploading(true);
    } else {
      setuploading(false);
    }
  };
  const onReplaceFileChange = (e) => {
    setLoading(false);
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      prepareToUpload(file);
      // setNewImage(e.target.files[0]);
      // setNewFile({ photoUrl: URL.createObjectURL(e.target.files[0]) });
    } else {
      clearFile();
    }
  };
  const onReplaceButtonClick = (image) => {
    setFileToUpdate(image);
  };

  const clearFile = () => {
    setNewImage(null);
    setNewFile(null);
    setFileToUpdate("");
    setLoading(false);
    setuploadProgress(0);
    setuploadProgressLabel("");
  };

  const uploadFile = async () => {
    try {
      setuploading(true);

      const newFile = await UserFilesService.uploadFile(
        newImage,
        fileToUpdate /*,
        (progress) => {
          const progressPercent = (
            (progress.loaded / progress.total) *
            100
          ).toFixed(2);
          setuploadProgress(progressPercent);
          setuploadProgressLabel(
            progressPercent < 100 ? `(${progressPercent}%)` : "Finishing up"
          );
        }*/
      );
      clearFile();
      let newImages = [newFile, ...userFiles];
      if (fileToUpdate) {
        newImages = removeFileFromList(fileToUpdate, newImages);
      }
      setUserFiles(newImages);
    } catch (e) {
      clearFile();
      setmessage({
        type: "error",
        text: t("files.fileUploadError"),
      });
    } finally {
      setuploading(false);
    }
  };

  const removeFileFromList = (file, list) => {
    return list?.filter((f) => f.fileName != file.fileName);
  };
  const DeleteImage = async (image) => {
    try {
      await UserFilesService.deleteUserImage(`${image.fileName}.${image.type}`);
      const newImages = removeFileFromList(image, userFiles);
      setUserFiles(newImages);
    } catch (error) {
      setmessage({
        type: "error",
        text: t("files.fileDeleteError"),
      });
    }
  };

  const getUserImages = async () => {
    const userImages = await UserFilesService.getUserPhotos();
    setUserFiles(userImages);
  };

  useEffect(() => {
    getUserImages();
    setFileToUpdate("");
  }, []);

  return (
    <Grid
      container
      justifyContent={"center"}
      height={1}
      spacing={3}
      padding={1}
    >
      {(!userFiles || userFiles.length < 15) && (
        <Grid item xs={12} sm={8} md={8} flexDirection={"row"} marginBottom={5}>
          {newFile && (
            <Card>
              <CardMedia
                className="userImage"
                component={newImage?.type?.includes("video") ? "video" : "img"}
                image={newFile.photoUrl}
                autoPlay
                muted
                controls={false}
              />
            </Card>
          )}
          {newImage != null && (
            <Stack padding={1}>
              {uploading && <LinearProgress />}
              <Typography textAlign={"center"}>
                {(newImage.size / (1024 * 1024)).toFixed(2)}MB{" "}
                {/* {uploadProgressLabel} */}
              </Typography>
            </Stack>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="space-between"
              >
                {!fileToUpdate && (
                  <Typography
                    variant="h4"
                    color="primary"
                    className="file-upload"
                  >
                    <label htmlFor="icon-button-file">
                      {t("files.addNewFile")}
                      <Iconify icon={"typcn:plus"} width={35} height={35} />
                    </label>
                    <Input
                      style={{ display: "none", visibility: "hidden" }}
                      inputProps={{ accept: "image/*" }}
                      id="icon-button-file"
                      name="userPhoto"
                      type="file"
                      onChange={onImageChange}
                    />
                  </Typography>
                )}
                {newFile && !loading && (
                  <IconButton onClick={clearFile}>
                    <Iconify icon="ep:close-bold" width={20} height={20} />
                  </IconButton>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  disabled={uploading}
                  size="large"
                  variant="outlined"
                  onClick={uploadFile}
                >
                  {fileToUpdate ? t("files.update") : t("files.upload")}
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <Stack paddingTop={1}>
            {message && <Alert severity={message.type}>{message.text}</Alert>}
          </Stack>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        flexDirection={"row"}
        justifyContent={"space-around"}
        marginBottom={5}
      >
        {userFiles ? (
          <Grid container justifyContent={"center"} height={1}>
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              flexDirection={"row"}
              justifyContent={"space-around"}
              marginBottom={5}
            >
              <Card>
                {userFiles.map((item, index) => (
                  <ImageListItem
                    key={`${item.fileName}`}
                    style={{
                      minHeight: 200,
                      marginBottom: 20,
                    }}
                  >
                    <RenderMedia file={item} />
                    <ImageListItemBar
                      actionIcon={
                        <Box
                          display={"flex"}
                          flex={1}
                          justifyContent="space-between"
                        >
                          <label htmlFor="icon-button-replace-file">
                            <IconButton
                              title={t("general.replace")}
                              component="span"
                              sx={{ color: "#fff" }}
                              onClick={() => onReplaceButtonClick(item)}
                            >
                              <Iconify
                                icon={"clarity:edit-solid"}
                                width={20}
                                height={20}
                              />
                            </IconButton>
                          </label>
                          <IconButton
                            sx={{ color: "#fff" }}
                            title={t("general.delete")}
                            onClick={() => DeleteImage(item, index)}
                          >
                            <Iconify
                              icon="gridicons:trash"
                              width={20}
                              height={20}
                            />
                          </IconButton>
                        </Box>
                      }
                    />
                  </ImageListItem>
                ))}
                <Input
                  hidden
                  style={{ display: "none", visibility: "hidden" }}
                  inputProps={{ accept: "image/*" }}
                  id="icon-button-replace-file"
                  name="userPhoto"
                  type="file"
                  onChange={onReplaceFileChange}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <UserCardSkeleton />
        )}
      </Grid>
    </Grid>
  );
}
