import { Container, Grid, Stack } from "@mui/material";
// @mui
import { useEffect, useState } from "react";
import { FeedService, LikeService } from "../services";
// components
import Page from "../components/Page";
import { UserCardSkeleton } from "src/sections/@main/UserFeed/UserCardSkeleton";
import UserCard from "src/sections/@main/UserFeed/userCard";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const BATCH_SIZE = 10;
let userFeed = [];
let profiles = [];
let popCount = 0;
export default function MainApp() {
  const [profile, setProfile] = useState(null);
  const [noProfiles, setnoProfiles] = useState(false);
  const [t] = useTranslation("global");
  const showNext = async () => {
    if (profiles.length > 0) {
      const next = profiles.pop();
      setProfile(next);
      popCount--;
    } else {
      setProfile(null);
      setnoProfiles(true);
    }
  };
  const likeUser = async (likedUser) => {
    await LikeService.sendLike([likedUser.uid]);
    showNext();
  };

  const passUser = async (passedUser) => {
    await LikeService.sendPass([passedUser.uid]);
  };
  const reportUser = async (reportedUser) => {
    await LikeService.reportUsser([reportedUser.uid]);
    showNext();
  };

  const initFeed = async () => {
    const resp = await FeedService.getFeed();
    if (resp && resp.length > 0) {
      userFeed = resp.filter((r) => !!r);
    }
    await getProfiles();
  };

  const getProfiles = async () => {
    const isAnyUidLeftToShow = userFeed?.length > 0;
    if (isAnyUidLeftToShow) {
      const uids = [];
      for (let i = 0; i < BATCH_SIZE && isAnyUidLeftToShow; i++) {
        const usr = userFeed.pop();
        if (usr) {
          uids.push(usr.uid);
        }
      }
      const newProfiles = await FeedService.getFeedProfiles(uids);

      const isAnyNewProfileToShow = newProfiles?.length > 0;
      if (isAnyNewProfileToShow) {
        newProfiles.forEach((p) => {
          if (p) {
            profiles.push(p);
          }
        });
        popCount = newProfiles.length;
        showNext();
      } else {
        await getProfiles();
      }
    } else {
      setnoProfiles(true);
    }
  };

  useEffect(() => {
    initFeed();
  }, []);

  return (
    <Page>
      <Container maxWidth="xl">
        {!profile && (
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={8} md={4} flexDirection={"row"}>
              {!noProfiles && <UserCardSkeleton />}
              {noProfiles && (
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    height: "80vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 style={{ textAlign: "center", color: "gray" }}>
                    {t("feed.noProfile")}
                  </h3>
                  <h4 style={{ textAlign: "center", color: "gray" }}>
                    {t("feed.comeBackLater")}
                  </h4>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
        {profile && (
          <Grid container justifyContent={"center"} height={1}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              flexDirection={"row"}
              justifyContent={"space-around"}
            >
              <UserCard
                user={profile}
                likeUser={likeUser}
                passUser={passUser}
                reportUser={reportUser}
                images={profile.images || []}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
