import { RestHelper } from './RestHelper';

const updateAutomatch = async (status) => {
    return await RestHelper.post('automatch', { status: status });
};

const getLiked = async (user) => {
    try {
        const response = await RestHelper.get('automatch/likes');
        return response.data;
    } catch (error) {
    }
    return [];
};
export const AutomatchService = {
    getLiked,
    updateAutomatch,
};