// material
import { Button, Card, Container, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { ContentSkeleton } from 'src/sections/@main/UserFeed/UserCardSkeleton';
import Profile from 'src/sections/@main/userLikes/ProfileCard';
import { AutomatchService } from 'src/services/AutomatchService';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default function Automatch() {
    const [users, setlikes] = useState([]);

    const getLikes = async () => {
        const userLikes = await AutomatchService.getLiked();
        setlikes(userLikes);
    };

    const turnOnAutoLike = async () => {
        await AutomatchService.updateAutomatch(true);
        getLikes();
    };
    const onMatch = () => { };
    useEffect(() => {
        getLikes();
    }, []);

    return (
        <Page title="Likes">
            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
          Likes
                </Typography>
                <Button onClick={turnOnAutoLike} >Turn on</Button>

                <Card>
                    <Grid container spacing={3}>
                        {!users ? <ContentSkeleton /> :
                            users.map((user) => (
                                <Grid key={`${user.userId}`} item xs={12} sm={6} md={3}>
                                    <Profile user={user} onMatch={onMatch} buttonText={'Like back'} />
                                </Grid>
                            ))}
                    </Grid>
                </Card>
            </Container>
        </Page>
    );
}
