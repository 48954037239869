import { gql, useQuery, useSubscription } from "@apollo/client";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { listMessages } from "src/graphql/queries";

import { Stack } from "@mui/material";
import { onCreateMessageByConversationId } from "src/graphql/subscriptions";
import { useAuthUser } from "src/contexts/UserContext";
import "./Message.css";
import * as subscriptions from "src/graphql/subscriptions";
import { ConfigManager } from "src/services/config/ConfigManager";
import UserAuthService from "src/services/UserAuthService";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";

const Message = ({ uid, message }) => {
  const style = {
    display: "flex",
    justifyContent: message.owner === uid ? "right" : "left",
  };
  return (
    <div style={style}>
      <p
        className={`chat__message  ${
          message.owner === uid && "chat__reciever"
        }`}
      >
        {message.text}
      </p>
    </div>
  );
};

export function MessageList({ conversation }) {
  const bottomRef = useRef();
  const user = useAuthUser();
  const uid = user.sub;
  const [messages, setMessages] = useState([]);
  const [maxHeight, setMaxHeight] = useState("80vh"); // Set an initial value

  const { data, loading, error } = useQuery(gql(listMessages), {
    variables: { conversationId: conversation.conversationId },
  });

  const { data: messageData, error: erroe2 } = useSubscription(
    gql(onCreateMessageByConversationId),
    {
      variables: { conversationId: conversation.conversationId },
    }
  );
 
  const scrollToBottom = (options) => {
    // Scroll to bottom after adding a new message
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView(options);
    }
  };

  useEffect(() => {
    if (messageData?.onCreateMessageByConversationId) {
      setMessages((prevMessages) => [
        ...prevMessages,
        messageData.onCreateMessageByConversationId,
      ]);
      scrollToBottom({ behavior: "smooth" });
    }
  }, [messageData]);

  // Set initial messages from the query
  useEffect(() => {
    if (data?.listMessages?.items) {
      setMessages(data.listMessages.items);
      scrollToBottom({});
    }
  }, [data]);

  // Update maxHeight dynamically based on window.innerHeight
  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(`${window.innerHeight * 0.75}px`);
    };

    handleResize(); // Set initial maxHeight
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once during component mount

  // Scroll to bottom initially
  useLayoutEffect(() => {
    if (bottomRef.current) {
      scrollToBottom({});
    }
  }, [messages]);
  return (
    <Stack
      style={{
        height: maxHeight,
        overflowY: "auto",
      }}
    >
      <Stack id="messagesContainer" spacing={1} padding={3}>
        {messages.map((message, i) => (
          <Message
            key={`${i}-${message.createdAt}`}
            message={message}
            uid={uid}
          />
        ))}
      </Stack>
      <div
        id="bottomID"
        key={"dummy"}
        ref={bottomRef}
        style={{ position: "relative" }}
      ></div>
    </Stack>
  );
}
