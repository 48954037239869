import { ConfigManager } from "./config/ConfigManager";
import { RestHelper } from "./RestHelper";
const baseUrl = ConfigManager.getUrl("userFiles");

const uploadFile = async (newFile, oldFile, progressCallback) => {
  const fileName = newFile.name;
  const fileSize = newFile.size;
  const fileType = newFile.type;
  const fileContents = newFile;

  const payload = {
    fileName: fileName,
    fileType: fileType,
  };
  if (oldFile && oldFile !== "") {
    payload.oldFile = `${oldFile.fileName}.${oldFile.type}`;
  }
  const presignedPostUrl = await RestHelper.post(
    `${baseUrl}user-files`,
    payload
  );

  const formData = new FormData();
  formData.append("file", newFile, presignedPostUrl.fileKey);

  const fileUpload = await fetch(presignedPostUrl.signedUrl, {
    method: "PUT",
    body: newFile,
  });
  if (!fileUpload.ok) {
    throw Error("File upload failed");
  }
  const newFileWithExtension = presignedPostUrl.fileKey.split("/").pop();

  const [newFileName, extension] = newFileWithExtension.split(".");
  return {
    fileName: newFileName,
    type: extension,
    crationDate: new Date().toISOString(),
  };
  // return RestHelper.postFile(presignedPostUrl.uploadUrl.url, formData, progressCallback);
};
const updateFile = async (oldFilename, newFile, progressCallback) => {
  const formData = new FormData();
  formData.append("name", "userPhoto");
  formData.append("userPhoto", newFile);
  return await RestHelper.putFile(
    "userfiles/upload",
    oldFilename,
    formData,
    progressCallback
  );
};

const getUserPhotos = async () => {
  try {
    const response = await RestHelper.get(`${baseUrl}user-files`);
    return response.sort((a,b)=> Date.parse(b.creationDate)-Date.parse(a.creationDate));
  } catch (error) {
    return [];
  }
};

const getFeedFiles = async (uid) => {
  try {
    if (!uid || uid.length === 0) {
      return [];
    }
    const response = await RestHelper.post(`${baseUrl}user-feed-files`, {
      uid: uid,
    });
    return response.data || [];
  } catch (error) {
    return [];
  }
};

const deleteUserImage = async (fileName) => {
  return await RestHelper.delete(`${baseUrl}user-files`, {
    fileName: fileName,
  });
};

export const UserFilesService = {
  uploadFile,
  updateFile,
  getUserPhotos,
  deleteUserImage,
  getFeedFiles,
};
