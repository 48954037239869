import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useState } from "react";

const DropdownFilter = ({
  data,
  initialValue,
  labelField,
  valueField,
  placeholder,
  onChange,
  renderValue
}) => {
  const label = labelField || "label";
  const options = data || [];
  const preSelected = initialValue || [];
  const [selection, setSelection] = useState(initialValue);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelection(typeof value === "string" ? value.split(",") : value);
    const val = typeof value === "string" ? value.split(",") : value;
    onChange(val);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dropdownFilter-label">{placeholder}</InputLabel>
      <Select
        labelId="dropdownFilter-label"
        id="dropdownFilter-checkbox"
        multiple
        value={selection || []}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={renderValue}
      >
        {options.map((option, ind) => (
          <MenuItem key={`dd${ind}`} value={option[valueField]}>
            <Checkbox checked={selection.indexOf(option[valueField]) > -1} />
            <ListItemText primary={option[label]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownFilter;
