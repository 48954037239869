import { CONFIG_DATA } from "./config";

export const ConfigManager = {
  getValue: (key) => CONFIG_DATA[key],
  getUrl: (key) => {
    let url = CONFIG_DATA["urls"][key];
    if (!url) return url;
    if (!url.startsWith("http")) {
      url = `https://${url}`;
    }
    return url;
  },
};
