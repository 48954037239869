// import { format, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

// export function fDate(date) {
//   return format(new Date(date), "dd MMMM yyyy");
// }

// export function fDateTime(date) {
//   return format(new Date(date), "dd MMM yyyy HH:mm");
// }

// export function fDateTimeSuffix(date) {
//   return format(new Date(date), "dd/MM/yyyy hh:mm p");
// }

// export function fToNow(date) {
//   return formatDistanceToNow(new Date(date), {
//     addSuffix: true,
//   });
// }
export function toAge(dateOfBirth) {
  try {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  } catch (e) {
    return "";
  }
}
