import { ConfigManager } from "./config/ConfigManager";
import { RestHelper } from "./RestHelper";
import UserAuthService from "./UserAuthService";
import { UserFilesService } from "./UserFilesService";

const baseUrl = ConfigManager.getUrl("users");

const updateProfile = async (payload) => {
  const section = payload.section;
  const data = payload.data;
  const profile = {
    name: data.name,
    birthday: data.birthday,
    bio: data.bio,
    country: data.country,
    homeCountry: data.homeCountry,
    state: data.state,
    city: data.city,
    address: data.address,
    gender: data.gender,
    education: data.education,
    profession: data.profession,

    height: data.height,
    heightRange: data.heightRange,
    maritalStatus: data.maritalStatus,
    hasChildren: data.hasChildren,
    canRelocate: data.canRelocate,

    practicing: data.practicing,
    pray: data.pray,
    smoke: data.smoke,
    drink: data.drink,
    halalOnly: data.halalOnly,

    hobbies: data.hobbies,
    languages: data.languages,
  };
  const requestPayload = {
    section: section,
    data: profile,
  };
  return await RestHelper.post(`${baseUrl}account`, requestPayload);
};

const getUserAccount = async () => {
  try {
    const response = await RestHelper.get(`${baseUrl}account`);
    return response;
  } catch (error) {
    return {};
  }
};
const getUserInfo = async () => {
  try {
    const result = await Promise.all([
      getUserAccount(),
      UserFilesService.getUserPhotos(),
    ]);
    return { ...result[0], files: result[1] };
  } catch (error) {
    return {};
  }
};

const updateQuestions = async (question) => {
  return await RestHelper.put("userinfo/questions", question);
};
const updateStatus = async () => {
  const { accessToken } = await UserAuthService.getAuthTokens();

  return await RestHelper.post(`${baseUrl}account/status`, {
    accessToken: accessToken,
  });
};

const getUsersBasicInfo = async (uids) => {
  if (!uids || uids.length === 0) {
    return [];
  }
  const response = await RestHelper.post(`${baseUrl}basicinfo`, {
    uid: uids,
  });
  if (response?.users?.length > 0) {
    const profiles = response.users.map((user) => {
      user.files = response?.files[user.uid];
      return user;
    });
    return profiles;
  }
  return [];
};

export const UserService = {
  getUserInfo,
  updateProfile,
  updateStatus,
  updateQuestions,
  getUsersBasicInfo
};
