import { Alert, Button, Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import DropdownFilter from "./DropdownFilter";
import { useTranslation } from "react-i18next";

const MaritalStatusFilter = ({ toggleView }) => {
  const [preferences, setpreferences] = useState();
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);
  const [t] = useTranslation("global");

  const initialData = [
    {
      status: "single",
      label: t("profile.aboutMe.single"),
    },
    {
      status: "divorcee",
      label: t("profile.aboutMe.divorced"),
    },
    {
      status: "other",
      label: t("profile.aboutMe.other"),
    },
  ];

  const onChange = (selection) => {
    const p = { ...preferences, maritalStatus: selection };
    setpreferences(p);
  };
  const saveMaritalStatus = async () => {
    try {
      setprocessing(true);
      const maritalStatusPreference = {
        section: "maritalStatus",
        maritalStatus:
          preferences.maritalStatus && preferences.maritalStatus.length === 3
            ? []
            : preferences.maritalStatus,
      };
      await PreferencesService.updatePreferences(maritalStatusPreference);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };
  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };
  const getStatusLabels = (keys) => {
    if (keys?.length > 0) {
      return initialData
        .filter((e) => keys.includes(e.status))
        .map((e) => e.label);
    }
    return [];
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"}>
      <DropdownFilter
        data={initialData}
        placeholder={t("profile.aboutMe.maritalStatus")}
        labelField={"label"}
        valueField={"status"}
        name="maritalStatus"
        initialValue={preferences?.maritalStatus || []}
        onChange={onChange}
        renderValue={(value) =>
          getStatusLabels(value).map((m) => (
            <Chip key={`${m}`} label={`${m}`} style={{ margin: 2 }} />
          ))
        }
      />

      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveMaritalStatus}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};
export default MaritalStatusFilter;
