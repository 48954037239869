// material
import { Card, Container, Grid, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
// ----------------------------------------------------------------------
// export default function UserLikes() {
//   return (
//     <Page title="Likes">
//       <Container>
//         <Typography variant="h4" sx={{ mb: 5 }}>
//           Likes
//         </Typography>
//         <UserLikesList />
//       </Container>
//     </Page>
//   );
// }
import * as React from "react";
import Page from "src/components/Page";
import LikedList from "src/sections/@main/userLikes/LikedList";
import PassedList from "src/sections/@main/userLikes/PassedList";
import { UserLikesList } from "../sections/@main";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function UserLikes() {
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation("global");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page>
      <Grid container justifyContent={"space-around"}>
        <Grid item xs={12} md={10} style={{ marginLeft: "auto" }}>
          <Card>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} >
                  <Tab
                    label={t("likes.myLikes")}
                    {...a11yProps(0)}
                    style={{display:"flex", flex:1}}
                  />
                  <Tab
                    label={t("likes.liked")}
                    {...a11yProps(1)}
                    style={{display:"flex", flex:1}}
                  />
                  <Tab
                    label={t("likes.passed")}
                    {...a11yProps(2)}
                    style={{display:"flex", flex:1}}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <UserLikesList />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <LikedList />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <PassedList />
              </TabPanel>
            </Box>
            {/*
            <AppBar
              position="static"
              style={{
                backgroundColor: "rgba(32, 101, 209, 0.08)",
                color: "#2065D1",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={t("likes.myLikes")} {...a11yProps(0)} />
                <Tab label={t("likes.liked")} {...a11yProps(1)} />
                <Tab label={t("likes.passed")} {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0}>
                <UserLikesList />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <LikedList />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <PassedList />
              </TabPanel>
            </SwipeableViews> */}
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}
