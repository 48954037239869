import { Alert, Button, Stack, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import AutoCompleteFilter from "./AutoCompleteFilter";
import { useTranslation } from "react-i18next";
import { i8Helper } from "src/utils/i18Helper";

const LocationFilter = ({ toggleView }) => {
  const [t] = useTranslation("global");
  const [canRelocate, setcanRelocate] = useState(false);
  const countriesObj = t("countries", { returnObjects: true });
  const contriesList = i8Helper.objectToList(countriesObj);
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);
  const [preferences, setpreferences] = useState();

  const saveLocationFilter = async () => {
    try {
      setprocessing(true);
      const locationFilter = {
        section: "location",
        country: preferences?.country || [],
        homeCountry: preferences?.homeCountry || [],
        canRelocate: canRelocate,
      };
      await PreferencesService.updatePreferences(locationFilter);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };
  const updateCountries = (selectedValue, key) => {
    const codes = selectedValue?.map((c) => c.value);
    const p = { ...preferences, [key]: codes };
    setpreferences(p);
  };

  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
      setcanRelocate(!!p.canRelocate);
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };
  const getCountries = (keys) => {
    if (keys?.length > 0) {
      return contriesList?.filter((c) => keys.includes(c.value));
    }
    return [];
  };
  useEffect(() => {
    getPreferences();
  }, []);

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"} paddingBottom={5}>
      <AutoCompleteFilter
        data={contriesList}
        placeholder={
          preferences?.country?.length > 0
            ? t("preferences.currentCountryOnly")
            : t("preferences.currentCountryAny")
        }
        selection={getCountries(preferences?.country)}
        onChange={(v, v2) => updateCountries(v2, "country")}
      />
      <AutoCompleteFilter
        data={contriesList}
        placeholder={
          preferences?.homeCountry?.length > 0
            ? t("preferences.originCountryOnly")
            : t("preferences.originCountryAny")
        }
        selection={getCountries(preferences?.homeCountry)}
        onChange={(v, v2) => updateCountries(v2, "homeCountry")}
      />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography>{t("preferences.willingToRelocate")}</Typography>
        <Switch checked={canRelocate} onChange={(e, v) => setcanRelocate(v)} />
      </Stack>
      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveLocationFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};
export default LocationFilter;
