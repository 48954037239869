import { createContext, useContext, useEffect, useState } from "react";
import UserAuthService from "src/services/UserAuthService";

export const UserContext = createContext();
export const AuthProviderContext = createContext();

export const useAuthUser = () => {
  return useContext(UserContext);
};

export const useAuthProvider = () => {
  return useContext(AuthProviderContext);
};

export function AuthenticationProvider({ children }) {
  const [user, setUser] = useState();

  const mergeAttributes = async (userData) => {
    const attributes = await UserAuthService.getUserAttributes();
    return { ...userData, ...attributes, uid: attributes.sub };
  };
  const authenticateUser = (userData) => {
    if (userData && userData !== null) {
      mergeAttributes(userData).then((data) => {
        setUser(data);
      });
    } else {
      setUser(undefined);
    }
  };
  const setCurrentUser = async () => {
    try {
      const loggedInUser = await UserAuthService.getCurrentUser();

      if (loggedInUser && loggedInUser !== null) {
        const userData = await mergeAttributes(loggedInUser);
        setUser(userData);
      } else {
        setUser(undefined);
      }
    } catch (error) {
      setUser(undefined);
    }
  };
  useEffect(() => {
    setCurrentUser();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <AuthProviderContext.Provider value={authenticateUser}>
        {children}
      </AuthProviderContext.Provider>
    </UserContext.Provider>
  );
}
