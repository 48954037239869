import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import AgeFilter from "src/sections/@main/preferences/AgeFilter";
import EducationFilter from "src/sections/@main/preferences/EducationFilter";
import HeightFilter from "src/sections/@main/preferences/HeightFilter";
import LocationFilter from "src/sections/@main/preferences/LocationFilter";
import MaritalStatusFilter from "src/sections/@main/preferences/MaritalStatusFilter";
import ProfessionFilter from "src/sections/@main/preferences/ProfessionFilter";
import ReligiosityFilter from "src/sections/@main/preferences/ReligiosityFilter";
import Iconify from "../components/Iconify";
import { filterOptions } from "src/constants/collections";
import { PreferencesProvider } from "src/contexts/PreferencesContext";

// components
import Page from "../components/Page";
import { useTranslation } from "react-i18next";



export default function Preferences() {
  const [t]= useTranslation("global");
  const [showView, setShowView] = useState(filterOptions.ALL);

   const preferencesMenuItems = [
    {
      view: filterOptions.LOCATION,
      icon: "carbon:location-filled",
      label: t("preferences.location"),
    },
    {
      view: filterOptions.MARITAL_STATUS,
      icon: "icon-park-outline:diamond-ring",
      label: t("preferences.maritalStatus"),
    },
    {
      view: filterOptions.PROFESSION,
      icon: "bytesize:work",
      label: t("preferences.profession"),
    },
    {
      view: filterOptions.RELIGION,
      icon: "fa-solid:mosque",
      label: t("preferences.religiosity"),

    },
    {
      view: filterOptions.EDUCATION,
      icon: "cil:education",
      label: t("preferences.education"),

    },
    {
      view: filterOptions.AGE,
      icon: "iwwa:year",
      label: t("preferences.age"),

    },
    {
      view: filterOptions.HEIGHT,
      icon: "mdi:human-male-height",
      label: t("preferences.height"),

    },
  ];
  const showFilterOptions = () => {
    setShowView(filterOptions.ALL);
  };

  return (
    <PreferencesProvider>
      <Page>
        <Container maxWidth="xl">
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8}>
              <Card style={{ height: "90vh" }}>
                <CardContent style={{height: "100vh" }}>
                  {showView === filterOptions.ALL && (
                    <Box>
                      {preferencesMenuItems.map((option) => (
                        <ListItem key={`${option.label}`} style={{ padding: 5 }}>
                          <ListItemButton
                            onClick={() => setShowView(option.view)}
                          >
                            <Iconify
                              icon={option.icon}
                              height={30}
                              width={30}
                            />
                            <Stack
                              direction={"row"}
                              flex={1}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              paddingLeft={1}
                            >
                              <Typography variant="h6">
                                {option.label}
                              </Typography>
                              <Iconify
                                icon={"akar-icons:chevron-right"}
                                height={30}
                                width={30}
                              />
                            </Stack>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Box>
                  )}
                  {showView === filterOptions.LOCATION && (
                    <LocationFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.MARITAL_STATUS && (
                    <MaritalStatusFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.PROFESSION && (
                    <ProfessionFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.RELIGION && (
                    <ReligiosityFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.EDUCATION && (
                    <EducationFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.AGE && (
                    <AgeFilter toggleView={showFilterOptions} />
                  )}
                  {showView === filterOptions.HEIGHT && (
                    <HeightFilter toggleView={showFilterOptions} />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </PreferencesProvider>
  );
}
