import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import RenderMedia from "../../../components/RenderMedia";
import Iconify from "../../../components/Iconify";
import { convertHeight } from "../../../utils/formatNumber";
import { toAge } from "../../../utils/formatTime";
import { VIDEO_TYPES } from "src/constants/collections";
import { ConfigManager } from "src/services/config/ConfigManager";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getDefaultPhoto, getFileUrl } from "src/utils/photoutil";
const picsDomain = ConfigManager.getValue("picsDomain");

export default function UserCard({
  user,
  likeUser,
  passUser,
  reportUser,
  images,
}) {
  const [t] = useTranslation("global");
  const professions = t("professions", { returnObjects: true });
  const countries = t("countries", { returnObjects: true });
  const languages = t("languages", { returnObjects: true });
  const [openDialog, setopenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const confirmReportUser = async (user) => {
    reportUser(user);
    setopenDialog(false);
  };
  const confirmReportUserHandler = () => {
    handleClose();
    setopenDialog(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ContentChip = ({ children }) => {
    return (
      <Stack
        spacing={1}
        direction={"row"}
        style={{
          backgroundColor: "#919eab3D",
          borderRadius: 10,
          padding: 5,
          alignItems: "center",
        }}
      >
        {children}
      </Stack>
    );
  };
  const topImage =
    images?.length > 0
      ? getFileUrl(user.uid, images[0])
      : getDefaultPhoto(user.gender);

  return (
    <Card>
      <Dialog open={openDialog} onClose={() => setopenDialog(false)}>
        <DialogTitle>{t("feed.reportUser")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("feed.confirmReportUser")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setopenDialog(false)}>
            {t("general.cancel")}
          </Button>
          <Button onClick={() => confirmReportUser(user)}>
            {t("feed.reportUser")}
          </Button>
        </DialogActions>
      </Dialog>

      <CardMedia
        className="userImage"
        component={
          images?.length > 0 && VIDEO_TYPES.includes(images[0].type)
            ? "video"
            : "img"
        }
        image={topImage}
        autoPlay
        muted
      />
      <div
        style={{
          justifyContent: "right",
          display: "flex",
          top: 0,
          position: "absolute",
          padding: 10,
          width: "100%",
        }}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Iconify icon="pepicons-pop:dots-y" width={40} height={40} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={confirmReportUserHandler}>
            <Iconify
              color="#FF6347"
              icon="ic:round-report"
              width={30}
              height={30}
            />
            <span style={{ paddingLeft: 10 }}>{t("feed.reportUser")}</span>
          </MenuItem>
        </Menu>
      </div>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          style={{ paddingBottom: 5 }}
        >
          <Typography gutterBottom variant="h5">
            {user.name}
          </Typography>
          {user.birthday && (
            <Typography gutterBottom variant="h5">
              {toAge(Date.parse(user.birthday))}
            </Typography>
          )}
        </Stack>
        <Box gap={1}>
          <Stack alignItems="left" gap={1} style={{ paddingBottom: 5 }}>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              bgcolor={"secondary"}
            >
              <Iconify icon={"carbon:location-filled"} width={26} height={26} />
              <Typography variant="body1">{user.address}</Typography>
            </Stack>
            {user.homeCountry && (
              <Stack direction="row" alignItems="center" gap={1}>
                <Iconify
                  icon={"carbon:location-heart-filled"}
                  width={26}
                  height={26}
                />
                <Typography variant="body1">
                  {countries[user.homeCountry]}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            style={{ paddingBottom: 5 }}
          >
            <Iconify icon={"ic:baseline-work"} width={26} height={26} />
            <Typography variant="body1">
              {professions[user.profession]}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <Iconify icon={"cil:education"} width={26} height={26} />
            <Typography variant="body1">{t(user.education)}</Typography>
          </Stack>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <IconButton color="error" size="large" onClick={() => passUser(user)}>
          <Iconify icon={"ep:close-bold"} width={52} height={52} />
        </IconButton>
        <IconButton color="error" size="large" onClick={() => likeUser(user)}>
          <Iconify icon={"ic:round-favorite"} width={52} height={52} />
        </IconButton>
      </CardActions>
      <CardContent>
        {user.bio && (
          <Stack spacing={3} paddingBottom={5}>
            <Divider>
              <Chip label={t("profile.aboutMe.intro")} />
            </Divider>
            <Typography variant="subtitle1">{user.bio}</Typography>
          </Stack>
        )}
      </CardContent>
      {images?.length > 1 && <RenderMedia file={images[1]} />}

      <CardContent>
        {"user.about" && (
          <Stack spacing={3} paddingBottom={5}>
            <Divider />
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ color: "text.secondary" }}
            >
              {t("profile.aboutMe.aboutMe")}
            </Typography>
            <Stack spacing={3}>
              {user.height > 0 && (
                <ContentChip>
                  <Iconify
                    icon={"mdi:human-male-height"}
                    width={22}
                    height={22}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    flex={1}
                  >
                    <Typography>
                      {`${user.height} ${t("profile.aboutMe.cm")}`}{" "}
                    </Typography>
                    <Typography>
                      {`${convertHeight(user.height, "cm", "feet")} ${t(
                        "profile.aboutMe.feet"
                      )}`}{" "}
                    </Typography>
                    <Typography>
                      {`${convertHeight(user.height, "cm", "meter")} ${t(
                        "profile.aboutMe.meters"
                      )}`}{" "}
                    </Typography>
                  </Stack>
                </ContentChip>
              )}
              {user.maritalStatus && (
                <ContentChip>
                  <Iconify
                    icon={"icon-park-outline:diamond-ring"}
                    width={22}
                    height={22}
                  />
                  <Typography>
                    {t(`profile.aboutMe.${user.maritalStatus}`)}
                  </Typography>
                </ContentChip>
              )}
              {user.hasChildren && (
                <ContentChip>
                  <Iconify icon={"cil:child"} width={22} height={22} />
                  <Typography>{t("profile.aboutMe.hasChildren")}</Typography>
                </ContentChip>
              )}
              {user.maritalStatus !== "single" && !user.hasChildren && (
                <ContentChip>
                  <Iconify icon={"cil:child"} width={22} height={22} />
                  <Typography>{t("profile.aboutMe.noChildren")}</Typography>
                </ContentChip>
              )}
              {user.canRelocate && (
                <ContentChip>
                  <Iconify
                    icon={"entypo:aircraft-take-off"}
                    width={22}
                    height={22}
                  />
                  <Typography>
                    {t(`profile.aboutMe.willingToRelocate`)}
                  </Typography>
                </ContentChip>
              )}
            </Stack>
          </Stack>
        )}
      </CardContent>
      {images?.length > 2 && <RenderMedia file={images[2]} />}
      <CardContent>
        {"user.religiosity" && (
          <Stack spacing={2} paddingBottom={5}>
            <Divider />
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ color: "text.secondary" }}
            >
              {t("profile.religiosity.religiosity")}
            </Typography>
            <Stack spacing={3} direction={"row"}>
              {user.practicing !== undefined && (
                <ContentChip>
                  <Iconify icon={"fa-solid:mosque"} width={22} height={22} />
                  <Typography>
                    {user.practicing === "notPracticing"
                      ? t("profile.religiosity.notPracticing")
                      : t("profile.religiosity.practicing")}
                  </Typography>
                </ContentChip>
              )}
              {user.pray && (
                <ContentChip>
                  <Iconify icon={"arcticons:ipray"} width={22} height={22} />
                  <Typography>
                    {t(`profile.religiosity.${user.pray}`)}{" "}
                  </Typography>
                </ContentChip>
              )}
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <ContentChip>
                <Iconify icon={"cil:smoke"} width={22} height={22} />
                <Typography>
                  {user.smoke
                    ? t(`profile.religiosity.smokes`)
                    : t(`profile.religiosity.noSmoke`)}{" "}
                </Typography>
              </ContentChip>
              <ContentChip>
                <Iconify icon={"bxs:drink"} width={22} height={22} />
                <Typography>
                  {user.smoke
                    ? t(`profile.religiosity.drink`)
                    : t(`profile.religiosity.noDrink`)}{" "}
                </Typography>
              </ContentChip>
            </Stack>
            <Stack spacing={3} direction={"row"}>
              <ContentChip>
                <Iconify icon={"bx:restaurant"} width={22} height={22} />
                <Typography>
                  {user.halalOnly
                    ? t(`profile.religiosity.halalOnly`)
                    : t(`profile.religiosity.noHalalOnly`)}{" "}
                </Typography>
              </ContentChip>
            </Stack>
          </Stack>
        )}
      </CardContent>
      {images?.length > 3 && <RenderMedia file={images[3]} />}

      <CardContent>
        {user.hobbies && user.hobbies.length > 0 && (
          <Stack spacing={3} paddingBottom={5}>
            <Divider />
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ color: "text.secondary" }}
            >
              {t(`profile.hobbiesAndLanguages.showHobbies`)}
            </Typography>
            <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
              {user.hobbies.map((hobby) => (
                <Chip key={`chip${hobby}`} label={hobby} />
              ))}
            </Stack>
          </Stack>
        )}
      </CardContent>
      {images?.length > 4 && <RenderMedia file={images[4]} />}
      <CardContent>
        {user.languages && user.languages.length > 0 && (
          <Stack spacing={3} paddingBottom={5}>
            <Divider />
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ color: "text.secondary" }}
            >
              {t(`profile.hobbiesAndLanguages.languages`)}
            </Typography>
            <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
              {user.languages.map((language) => (
                <Chip key={`chip${language}`} label={languages[language]} />
              ))}
            </Stack>
          </Stack>
        )}
      </CardContent>
      {images?.length > 5 &&
        images.slice(5).map((image) => (
          <div key={`div${image.fileName}`} style={{ marginBottom: 2 }}>
            <RenderMedia key={`img${image.fileName}`} file={image} />
          </div>
        ))}
    </Card>
  );
}
