import { Container, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import UserCard from "src/sections/@main/UserFeed/userCard";
import { UserCardSkeleton } from "src/sections/@main/UserFeed/UserCardSkeleton";
// components
import Page from "../components/Page";
import { useEffect, useState } from "react";
import { FeedService } from "src/services";

export default function UserProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setuser] = useState(location?.state?.user);
  const showProfile = async (uid) => {
    const response = await FeedService.getFeedProfiles([uid]);
    if (response.length > 0) {
      setuser(response[0]);
    }
  };
  const stateUid = location?.state?.uid;

  useEffect(() => {
    if (stateUid) {
      showProfile(stateUid);
    } else if (!user) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <Page>
      <Container maxWidth="xl">
        {!user ? (
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={8} md={4} flexDirection={"row"}>
              <UserCardSkeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent={"center"} height={1}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              flexDirection={"row"}
              justifyContent={"space-around"}
              marginBottom={5}
            >
              <UserCard user={user} images={user.files} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
