import { Container, Fab, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import UserCard from "src/sections/@main/UserFeed/userCard";
import { UserCardSkeleton } from "src/sections/@main/UserFeed/UserCardSkeleton";
import Iconify from "../components/Iconify";
// components
import Page from "../components/Page";
import { UserService } from "../services";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function ProfilePreview() {
  const [user, setProfile] = useState(null);
  const [t] = useTranslation("global");
  const navigate = useNavigate();

  const getUserInfo = async () => {
    const userInfo = await UserService.getUserInfo();
    setProfile(userInfo);
  };
  const navigateToProfileUpdator = () => {
    navigate("/profile", { replace: true });
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Page title="Preview">
      <Container maxWidth="xl">
        <Stack
          spacing={3}
          direction={"row-reverse"}
          marginRight={2}
          marginBottom={2}
          alignItems={"center"}
        >
          <Fab
            size={"large"}
            variant="extended"
            color="secondary"
            aria-label="edit"
            onClick={navigateToProfileUpdator}
          >
            <Iconify icon={"akar-icons:edit"} width={22} height={22} />
            {t("general.edit")}
          </Fab>
        </Stack>
        {!user ? (
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={8} md={4} flexDirection={"row"}>
              <UserCardSkeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent={"center"} height={1}>
            <Grid
              item
              xs={12}
              sm={8}
              md={4}
              flexDirection={"row"}
              justifyContent={"space-around"}
              marginBottom={5}
            >
              <UserCard
                user={user}
                images={user.files}
                likeUser={() => {}}
                passUser={() => {}}
                reportUser={() => {}}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
