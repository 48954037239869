import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { createMessage } from "src/graphql/mutations";

import { IconButton,Box } from "@mui/material";
import Iconify from "src/components/Iconify";
import { useTranslation } from "react-i18next";

export function MessageSender({ conversationId }) {
  const [t]= useTranslation("global");
  const [messageText, setMessageText] = useState("");

  const [createMessageMutation, { loading, error }] = useMutation(
    gql(createMessage)
  );
  
  const sendMessage = async () => {
    if (messageText.trim() !== "") {
      try {
        const { data, errors } = await createMessageMutation({
          variables: {
            input: { text: messageText, conversationId: conversationId },
          },
        });
        setMessageText("");
      } catch (e) {}
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <Box className="chat__footer">
      <div className="form" autoComplete="off">
        <input
          type="text"
          id="messageInput"
          autoComplete="off"
          placeholder={t("messages.typeMessage")}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          onClick={sendMessage}
          className="chat_submit"
          type="submit"
        ></button>
        <IconButton onClick={sendMessage}>
          <Iconify
            icon="fluent:send-16-filled"
            sx={{ width: 30, height: 30 }}
          />
        </IconButton>
      </div>
    </Box>
  );
}
