export const Collections = {
  userLikes: "users/likes",
};

export const filterOptions = {
  ALL: 0,
  LOCATION: 1,
  PROFESSION: 2,
  MARITAL_STATUS: 3,
  EDUCATION: 4,
  RELIGION: 5,
  HEIGHT: 6,
  AGE: 7,
};

export const VIDEO_TYPES = ["mp4", "avi", "mkv", "mov", "wmv"];
