import {
  Autocomplete,
  Chip,
  Stack,
  TextField,
} from "@mui/material";

const AutoCompleteFilter = ({
  data,
  selection,
  labelField,
  placeholder,
  onChange,
}) => {
  const label = labelField || "label";
  const textPlaceholder = placeholder || "Type to see options";

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Autocomplete
        fullWidth
        multiple
        id="tags-filled"
        options={data || []}
        onChange={onChange}
        value={selection || []}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option[label]}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={textPlaceholder} />
        )}
      />
    </Stack>
  );
};

export default AutoCompleteFilter;
