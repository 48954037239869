import { Grid } from "@mui/material";
import { useState } from "react";
import UserConversations from "../sections/@messaging/UserConversations";
import "./Message.css";
import { ApolloProvider } from "@apollo/client";
import { client } from "src/graphql/Client";
import UserMessages from "src/sections/@messaging/UserMessages";

export default function Messages() {
  const [conversation, setConversation] = useState();
  const [showConversations, setshowConversations] = useState(true);

  const onConversatonClick = (selectedConversation, userInfo) => {
    setConversation((prev) => {
      return { ...selectedConversation, userInfo };
    });
    toggleConversationView();
  };
  const toggleConversationView = () => {
    setshowConversations((prev) => !showConversations);
  };

  return (
    <Grid container justifyContent={"center"}>
      <ApolloProvider client={client}>
        {showConversations ? (
          <Grid item xs={12} md={6}>
            <UserConversations conversationClickCallback={onConversatonClick} />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <UserMessages
              conversation={conversation}
              backCallback={toggleConversationView}
            />
          </Grid>
        )}
      </ApolloProvider>
    </Grid>
  );
}
