import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreferencesService } from "src/services/PreferencesService";
import { ContentSkeleton } from "../UserFeed/UserCardSkeleton";
import { i8Helper } from "src/utils/i18Helper";

const AgeFilter = ({ toggleView }) => {
  const [t] = useTranslation("global");

  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(101);
  const [preferences, setpreferences] = useState();
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);

  const saveAgeFilter = async () => {
    try {
      setprocessing(true);
      const locationFilter = {
        section: "age",
        minAge: minAge,
        maxAge: maxAge,
      };
      await PreferencesService.updatePreferences(locationFilter);
      setMessage({
        type: "success",
        text: t("preferences.preferencesSaved"),
      });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  const getPreferences = async () => {
    try {
      const p = await PreferencesService.getUserPreferences();
      setpreferences(p);
      if (p?.minAge >= 18) {
        setMinAge(p.minAge);
      }
      if (p?.maxAge >= 18) {
        setMinAge(p.maxAge);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    }
  };

  const ageObject = t("age", { returnObjects: true });
  const ageList = i8Helper.objectToList(ageObject);

  useEffect(() => {
    getPreferences();
  }, []);

  const handleMinAgeChange = (event) => {
    const newMinAge = parseInt(event.target.value, 10);
    setMinAge(newMinAge);
    if (newMinAge > maxAge) {
      setMaxAge(newMinAge);
    }
  };

  const handleMaxAgeChange = (event) => {
    const newMaxAge = parseInt(event.target.value, 10);
    setMaxAge(newMaxAge);
    if (newMaxAge < minAge) {
      setMinAge(newMaxAge);
    }
  };

  return !preferences ? (
    <ContentSkeleton />
  ) : (
    <Stack spacing={3} justifyContent={"center"} padding={5}>
      <Stack spacing={5}>
        <FormControl fullWidth>
          <InputLabel>{t("preferences.minAge")}</InputLabel>
          <Select value={minAge} onChange={handleMinAgeChange}>
            {ageList.map((age) => (
              <MenuItem key={`${age.value}`} value={age.value}>
                {age.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>{t("preferences.maxAge")}</InputLabel>
          <Select value={maxAge} onChange={handleMaxAgeChange}>
            {ageList.map((age) => (
              <MenuItem key={`${age.value}`} value={age.value}>
                {age.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {message && <Alert severity={message.type}>{message.text}</Alert>}

      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={saveAgeFilter}
        disabled={processing}
      >
        {t("general.save")}
      </Button>
      <Button color="error" onClick={toggleView}>
        {t("general.back")}
      </Button>
    </Stack>
  );
};

export default AgeFilter;
