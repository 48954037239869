import { useEffect, useState } from "react";
import "./App.css";
import { useAuthUser } from "./contexts/UserContext";
import Login from "./pages/Login";
import Router from "./routes";
import ThemeProvider from "./theme";
const animation = require("./assets/images/round-lift.gif");

// ----------------------------------------------------------------------

export default function App() {
  const [loading, setloading] = useState(true);
  const user = useAuthUser();

  const causeDelay = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  useEffect(() => {
    causeDelay().then(() => {
      setloading(false);
    });
  }, []);

  const SplashScreen = () => {
    return (
      <>
        <div className="splash-background"></div>
        <div className="bg-text">
          <img
            alt="animation"
            src={animation}
            style={{
              maxHeight: "100vh",
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
      </>
    );
  };
  return (
    <ThemeProvider>
      {loading ? (
        <SplashScreen />
      ) : user && user !== null ? (
        <Router />
      ) : (
        <Login />
      )}
    </ThemeProvider>
  );
}
