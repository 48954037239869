import {
  Alert,
  Autocomplete,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import LocationAutoComplete from "../../../components/LocationAutoComplete";
import { UserService } from "../../../services";
import { toAge } from "../../../utils/formatTime";
import { UserCardSkeleton } from "../UserFeed/UserCardSkeleton";
import { useAuthProvider, useAuthUser } from "src/contexts/UserContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { i8Helper } from "src/utils/i18Helper";

export default function BasicInfoEditor({ backCallback }) {
  const [currentUser, setProfile] = useState();
  const [query, setQuery] = useState("");

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [message, setMessage] = useState();
  const [processing, setprocessing] = useState(false);

  const authUser = useAuthUser();
  const setAuthUser = useAuthProvider();

  const navigate = useNavigate();

  const getDob = () => {
    if (year) {
      const d = day || 31;
      const m = month || 12;
      const dateObject = new Date(`${year}-${m}-${d}`);
      return dateObject;
    } else {
      return null;
    }
  };

  const getUserInfo = async () => {
    const userInfo = await UserService.getUserInfo();
    if (userInfo.birthday) {
      const date = new Date(userInfo.birthday);
      setDay(date.getDate());
      setMonth(date.getMonth());
      setYear(date.getFullYear());
    }
    setProfile(userInfo);
    setQuery(userInfo.address || "");
  };

  const getErrors = (required, data) => {
    const showError = (field, data) => {
      if (field === "birthday") {
        return !data[field] || toAge(data[field]) < 18;
      }
      return !data[field];
    };
    const errors = [];
    for (let i = 0; i < required.length; i++) {
      if (showError(required[i], data)) {
        errors.push(required[i]);
      }
    }
    return errors.length > 0 ? errors : null;
  };
  const onformSumit = async (e) => {
    e.preventDefault();
    const birthday = getDob();
    let p = {
      ...currentUser,
      birthday: birthday,
    };
    if (!currentUser.country && query && query.trim().length > 3) {
      p = {
        ...p,
        country: query,
      };
    }
    setProfile(p);

    const required = ["name", "gender", "birthday", "country"];
    const errors = getErrors(required, p);
    if (!errors) {
      await updateProfile(p);
    } else {
      const missingFields = errors
        .map((e) => {
          return e === "country"
            ? t("profile.basicInfo.currentCity")
            : t(`profile.basicInfo.${e}`);
        })
        .join(", ");

      let errorMessage = `${t("general.missingFieldsError")} ${missingFields}`;
      if (toAge(p.birthday) < 18) {
        errorMessage = t("profile.basicInfo.underAgeError");
      }

      setMessage({
        type: "error",
        text: errorMessage,
      });
    }
  };

  const onInputChange = (e) => {
    setProfile({
      ...currentUser,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = async (data) => {
    try {
      setprocessing(true);
      await UserService.updateProfile({
        section: "basicInfo",
        data: data,
      });

      // const updatedUser = await UserAuthService.getCurrentUser();
      // setUser(updatedUser);
      const msg = {
        type: "success",
        text: t("profile.basicInfo.profileUpdated"),
      };
      setMessage(msg);
      setprocessing(false);
      if (authUser.profileStatus !== "complete") {
        await UserService.updateStatus();
        setAuthUser({ ...authUser, profileStatus: "complete" });
      }

      if (backCallback) {
        backCallback(msg);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
      setprocessing(false);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getAddress = (placeResult) => {
    const addressObj = { country: "", state: "", city: "", address: "" };
    if (placeResult.address_components.length > 1) {
      addressObj.country =
        placeResult.address_components[
          placeResult.address_components.length - 1
        ].short_name;
      if (placeResult.address_components.length > 2) {
        addressObj.state =
          addressObj.country +
          "-" +
          placeResult.address_components[
            placeResult.address_components.length - 2
          ].short_name;
      }
      addressObj.city =
        addressObj.state + "-" + placeResult.address_components[0].short_name;
    }
    addressObj.address = placeResult.formatted_address;
    if (!addressObj.state) {
      addressObj.state = addressObj.address;
    }
    if (!addressObj.country) {
      addressObj.country = addressObj.address;
    }
    return addressObj;
  };
  const onPlaceSelected = (placeResult) => {
    const addressObj = getAddress(placeResult);
    setProfile((prev) => {
      return {
        ...prev,
        country: addressObj.country,
        state: addressObj.state,
        city: addressObj.city,
        address: addressObj.address,
      };
    });
  };

  const [t] = useTranslation("global");

  function getEighteenYearsAgoYear() {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return eighteenYearsAgo.getFullYear();
  }

  const professions = t("professions", { returnObjects: true });
  const countries = t("countries", { returnObjects: true });
  const days = t("days", { returnObjects: true });
  const months = t("months", { returnObjects: true });
  const years = t("years", { returnObjects: true });

  const professionList = i8Helper.objectToList(professions);
  const countryList = i8Helper.objectToList(countries);
  const dayList = i8Helper.objectToList(days);
  const monthList = i8Helper.objectToList(months);
  const eighteenthYear = getEighteenYearsAgoYear();
  const yearList = i8Helper
    .objectToList(years)
    .filter((y) => y.value <= eighteenthYear);
  return currentUser ? (
    <form autoComplete="off">
      <Stack>
        <CardContent>
          <Stack spacing={3}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ color: "text.secondary" }}
            >
              {t("profile.basicInfo.basicInfo")}
            </Typography>
            <TextField
              fullWidth
              label={t("profile.basicInfo.name")}
              name={"name"}
              value={currentUser.name || ""}
              onChange={onInputChange}
            />
            <FormControl>
              <FormLabel id="user_gender">Gender</FormLabel>
              <RadioGroup row aria-labelledby="user_gender" name="gender">
                <FormControlLabel
                  value="female"
                  control={<Radio checked={currentUser.gender === "female"} />}
                  label={t("profile.basicInfo.female")}
                  onChange={() =>
                    onInputChange({
                      target: { name: "gender", value: "female" },
                    })
                  }
                />
                <FormControlLabel
                  value="male"
                  control={<Radio checked={currentUser.gender === "male"} />}
                  label={t("profile.basicInfo.male")}
                  onChange={() =>
                    onInputChange({ target: { name: "gender", value: "male" } })
                  }
                />
              </RadioGroup>
            </FormControl>
            <Stack direction={"row"} spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="day-label">
                  {t("profile.basicInfo.day")}
                </InputLabel>
                <Select
                  labelId="selected-day-label"
                  id="education-select"
                  value={day || ""}
                  label={t("profile.basicInfo.day")}
                  onChange={(e) => setDay(e.target.value)}
                  name="education"
                >
                  {dayList.map((d) => (
                    <MenuItem key={`day${d.value}`} value={d.value}>
                      {d.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="month-label">
                  {t("profile.basicInfo.month")}
                </InputLabel>
                <Select
                  labelId="selected-month-label"
                  id="education-select"
                  value={month || ""}
                  label={t("profile.basicInfo.month")}
                  onChange={(e) => setMonth(e.target.value)}
                  name="education"
                >
                  {monthList.map((m) => (
                    <MenuItem key={`month${m.value}`} value={m.value}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="year-label">
                  {t("profile.basicInfo.year")}
                </InputLabel>
                <Select
                  labelId="selected-year-label"
                  id="education-select"
                  value={year || ""}
                  label={t("profile.basicInfo.year")}
                  onChange={(e) => setYear(e.target.value)}
                  name="education"
                >
                  {yearList.map((y) => (
                    <MenuItem key={`year${y.value}`} value={y.value}>
                      {y.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <LocationAutoComplete
              onPlaceSelected={onPlaceSelected}
              stateValue={[query, setQuery]}
              label={t("profile.basicInfo.currentCity")}
            />
            <Autocomplete
              freeSolo
              autoHighlight
              fullWidth
              options={countryList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key="homeCountryLable"
                  label={t("profile.basicInfo.homeCountry")}
                />
              )}
              name={"homeCountry"}
              value={countries[currentUser.homeCountry] || ""}
              onChange={(v, v2) =>
                onInputChange({
                  target: { name: "homeCountry", value: v2?.value },
                })
              }
            />
            <Autocomplete
              freeSolo
              autoHighlight
              fullWidth
              options={professionList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("profile.basicInfo.profession")}
                />
              )}
              name={"profession"}
              value={professions[currentUser.profession] || ""}
              onChange={(v, v2) =>
                onInputChange({
                  target: { name: "profession", value: v2.value },
                })
              }
            />
            <FormControl fullWidth>
              <InputLabel id="education-select-label">
                {t("profile.basicInfo.education")}
              </InputLabel>
              <Select
                labelId="education-select-label"
                id="education-select"
                value={currentUser.education || ""}
                label={t("profile.basicInfo.education")}
                onChange={onInputChange}
                name="education"
              >
                <MenuItem value={"phd"}>
                  {t("profile.basicInfo.qualifications.phd")}
                </MenuItem>
                <MenuItem value={"masters"}>
                  {t("profile.basicInfo.qualifications.masters")}
                </MenuItem>
                <MenuItem value={"graduate"}>
                  {t("profile.basicInfo.qualifications.bachelors")}
                </MenuItem>
                <MenuItem value={"undergraduate"}>
                  {t("profile.basicInfo.qualifications.underGraduate")}
                </MenuItem>
                <MenuItem value={"other"}>
                  {" "}
                  {t("profile.basicInfo.qualifications.other")}
                </MenuItem>
              </Select>
            </FormControl>

            {message && <Alert severity={message.type}>{message.text}</Alert>}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onformSumit}
              disabled={processing}
            >
              {t("general.save")}
            </Button>
          </Stack>
        </CardContent>
      </Stack>
    </form>
  ) : (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} sm={6} md={4} flexDirection={"row"}>
        <UserCardSkeleton />
      </Grid>
    </Grid>
  );
}
