import {
  Alert,
  Button,
  CardContent,
  Grid,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { UserService } from "../../../services";
import { UserCardSkeleton } from "../UserFeed/UserCardSkeleton";
import { useTranslation } from "react-i18next";

export default function ReligiosityEditor({backCallback}) {
  const [user, setProfile] = useState();
  const [processing, setprocessing] = useState(false);
  const [message, setMessage] = useState();
  const [t] = useTranslation("global");

  const getUserInfo = async () => {
    const userInfo = await UserService.getUserInfo();
    setProfile(userInfo);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const onformSumit = (e) => {
    e.preventDefault();
    updateProfile();
  };
  const onInputChange = (e) => {
    setProfile({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = async () => {
    try {
      setprocessing(true);
      await UserService.updateProfile({
        section: "religiosityInfo",
        data: user,
      });
      
      const msg = {
        type: "success",
        text: t("profile.basicInfo.profileUpdated"),
      };
      setMessage(msg);
      setprocessing(false);
      if (backCallback) {
        backCallback(msg);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: t("general.requestError"),
      });
    } finally {
      setprocessing(false);
    }
  };

  return user ? (
    <form autoComplete="off">
      <Stack>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            sx={{ color: "text.secondary" }}
          >
            {t("profile.religiosity.religiosity")}
          </Typography>

          <Stack spacing={3} marginTop={3}>
            <FormControl fullWidth>
              <InputLabel id="practicingSelectLabel">
                {t("profile.religiosity.practicing")}
              </InputLabel>
              <Select
                labelId="practicingSelectLabel"
                id="practicingSelect"
                value={user.practicing || ""}
                label={t("profile.religiosity.practicing")}
                name="practicing"
                onChange={onInputChange}
              >
                <MenuItem value={"practicing"}>
                  {t("profile.religiosity.practicing")}
                </MenuItem>
                <MenuItem value={"notPracticing"}>
                  {t("profile.religiosity.notPracticing")}
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="praySelectLabel">
                {" "}
                {t("profile.religiosity.pray")}
              </InputLabel>
              <Select
                labelId="praySelectLabel"
                id="praySelect"
                value={user.pray || ""}
                label={t("profile.religiosity.pray")}
                name="pray"
                onChange={onInputChange}
              >
                <MenuItem></MenuItem>
                <MenuItem value={"pray"}>
                  {" "}
                  {t("profile.religiosity.pray")}
                </MenuItem>
                <MenuItem value={"notPray"}>
                  {" "}
                  {t("profile.religiosity.notPray")}
                </MenuItem>
              </Select>
            </FormControl>

            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("profile.religiosity.smoke")}</Typography>
              <Switch
                checked={user.smoke || false}
                onChange={(e, v) =>
                  onInputChange({ target: { name: "smoke", value: v } })
                }
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("profile.religiosity.drink")}</Typography>
              <Switch
                checked={user.drink || false}
                onChange={(e, v) =>
                  onInputChange({ target: { name: "drink", value: v } })
                }
              />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>{t("profile.religiosity.halalOnly")}</Typography>
              <Switch
                checked={user.halalOnly === undefined ? true : user.halalOnly}
                onChange={(e, v) =>
                  onInputChange({ target: { name: "halalOnly", value: v } })
                }
              />
            </Stack>
            {message && <Alert severity={message.type}>{message.text}</Alert>}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onformSumit}
              disabled={processing}
            >
              {t("general.save")}
            </Button>
          </Stack>
        </CardContent>
      </Stack>
    </form>
  ) : (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} sm={6} md={4} flexDirection={"row"}>
        <UserCardSkeleton />
      </Grid>
    </Grid>
  );
}
